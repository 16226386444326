import { useListView } from '../../core/ListViewProvider'
import {GradeEditModalForm} from './GradeEditModalForm'

const GradeEditModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()

  //@ts-ignore
  return  <GradeEditModalForm grade={itemIdForUpdate} />
}

export {GradeEditModalFormWrapper}

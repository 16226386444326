import {UsersListToolbar} from './UserListToolbar'

const UsersListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar'>
        <UsersListToolbar />
      </div>
    </div>
  )
}

export {UsersListHeader}

import {FC, useState, createContext, useContext} from 'react'
import { WebhookLogginItem } from './_models'

const ListViewContext = createContext<{itemIdForUpdate?: WebhookLogginItem, setItemIdForUpdate?: any}>({})

const ListViewProvider: FC = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<WebhookLogginItem>()

  return (
    <ListViewContext.Provider
      value={{
        itemIdForUpdate,
        setItemIdForUpdate,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}

import { useMemo } from "react"
import { usersColumns } from "./resouces/table/columns/_columns_3"
import { EventsListWrapper } from "./resouces/ResourcesList"

const ResourcesPage = () => {
  const columns = useMemo(() => usersColumns, []);

  return <EventsListWrapper columns={columns} />
}

export default ResourcesPage
import {FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import EventsPage from '../modules/events/EventsPage'
import OperatorsPage from '../modules/operators/operators'
import GradesPage from '../modules/grades/GradesPage'
import { OperatorsStaffPage } from '../modules/operatorsStaff/operators'
import ResourcesPage from '../modules/resources/ResourcesPage'
import BatchesPage from '../modules/batches/BatchesPage'
import BurningItemsPage from '../modules/BurningItems/BurningItemsPage'
import WebhooksLoggingPage from '../modules/WebhooksLogging/WebhooksLoggingPage'
import FailedEventsPage from '../modules/failedEvents/FailedEventsPage'
import TranslationPage from '../modules/Translation/ResourcesPage'

const PrivateRoutes = () => {
  // const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  // const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  // const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  // const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  // const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  // const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  //const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        {/* Pages */}
        <Route path="dashboard" element={<DashboardWrapper />} />
        <Route
          path="events"
          element={
            <SuspensedView>
              <EventsPage />
            </SuspensedView>
          }
        />
        <Route
          path="grades"
          element={
            <SuspensedView>
              <GradesPage />
            </SuspensedView>
          }
        />
        <Route
          path="operators"
          element={
            <SuspensedView>
              <OperatorsPage />
            </SuspensedView>
          }
        />
        <Route
          path="staff-operators"
          element={
            <SuspensedView>
              <OperatorsStaffPage />
            </SuspensedView>
          }
        />
        <Route
          path="resources"
          element={
            <SuspensedView>
              <ResourcesPage />
            </SuspensedView>
          }
        />
        <Route
          path="batches"
          element={
            <SuspensedView>
              <BatchesPage />
            </SuspensedView>
          }
        />
        <Route
          path="burning-items"
          element={
            <SuspensedView>
              <BurningItemsPage />
            </SuspensedView>
          }
        />
        <Route
          path="webhooks-logging"
          element={
            <SuspensedView>
              <WebhooksLoggingPage />
            </SuspensedView>
          }
        />

        <Route
          path="failed-events"
          element={
            <SuspensedView>
              <FailedEventsPage />
            </SuspensedView>
          }
        />
        <Route
          path="translation"
          element={
            <SuspensedView>
              <TranslationPage />
            </SuspensedView>
          }
        />

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
}

const SuspensedView: FC = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}

import {Column, HeaderProps} from 'react-table'
import {UserCustomHeader} from './UserCustomHeader'

export type OperatorStaff = {
  id: string;
  email: string;
  operatorId: string;
}

const usersColumns: ReadonlyArray<Column<OperatorStaff>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='id' className='min-w-125px' />,
    accessor: 'id',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'email',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='operator_Id' className='min-w-125px' />,
    accessor: 'operatorId',
  },
  // {
  //   id: 'actions',
  //   Cell: ({...props}) => <UserActionsCell id={props.data[props.row.index].id} />,
  // },
]

export {usersColumns}

import clsx from 'clsx'
import {FC} from 'react'
import { getCellContent } from '../../../../../utils/utils'
import { WebhooksStatusEnum } from '../../../../modules/WebhooksLogging/webhooks-logging-page/core/_models'
import { UniversalRowProps } from './UniversalProps'

const CustomRow: FC<UniversalRowProps> = ({row}) => (
  //@ts-ignore
  <tr {...row.getRowProps()} style={{backgroundColor: row?.original?.status_code !== undefined ? row?.original?.status_code === WebhooksStatusEnum.SUCCESS ? '#d4e6da' : '#f9bfbf': ''}}>
    {row.cells.map((cell) => {
      const cellContent = getCellContent(cell)
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cellContent}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}

import {Column, HeaderProps} from 'react-table'
import { TableCustomHeader } from '../../../../../components/Table/table/columns/TableCustomHeader'
import { UniversalContent } from '../../../../../components/Table/table/columns/UniversalProps'
import { useListView } from '../../core/ListViewProvider'
import {UserCustomHeader} from './UserCustomHeader'
import pencil from '../../../../../../icons/pencil.svg'

export type Grade = {
  name: string,
  eventAmount: string,
  mintPercentage: string,
  operatorResourceId: string,
  poolPercentage: string,
}

const usersColumns: ReadonlyArray<Column<Grade>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='name' className='min-w-150px' />,
    accessor: 'name',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='resouce_id' className='min-w-150px' />,
    accessor: 'operatorResourceId',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='mintPercentage' className='min-w-150px' />,
    accessor: 'mintPercentage',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='percentage' className='min-w-150px' />,
    accessor: 'poolPercentage',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='amount' className='min-w-125px' />,
    accessor: 'eventAmount',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader tableProps={props} title='UPDATE' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const {setItemIdForUpdate} = useListView()

      const openWebhooksInfoModal = ()=> {
        setItemIdForUpdate(props.data[props.row.id])
      }

        return (
          <div onClick={openWebhooksInfoModal}>
            <img alt='' src={pencil} style={{marginRight: 20, cursor: 'pointer'}}/>
          </div>
        )
      },
  },
]

export {usersColumns}

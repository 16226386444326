import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/UsersTable'
import {KTCard} from '../../../../_metronic/helpers'
import { Column } from 'react-table'
import { User } from './core/_models'
import { EventType } from './table/columns/_columns_1'

const EventsList = ({columns}: {columns: ReadonlyArray<Column<EventType>>}) => {
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable columns={columns} />
      </KTCard>
    </>
  )
}

const EventsListWrapper = ({columns}: {columns: ReadonlyArray<Column<EventType>>}) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
        <EventsList columns={columns} />
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EventsListWrapper}

import { useMemo } from "react"
import { usersColumns } from "./grades-list/table/columns/_columns_3"
import { EventsListWrapper } from "./grades-list/GradesList"

const GradesPage = () => {
  const columns = useMemo(() => usersColumns, []);

  return (
    <>
      <EventsListWrapper columns={columns} />
    </>
  )
}

export default GradesPage
import {FC, useState, createContext, useContext, useEffect} from 'react'
import { AddOperatorGradeDto } from './_models'

const ListViewContext = createContext<{itemIdForUpdate?: AddOperatorGradeDto, setItemIdForUpdate?: any}>({})

const ListViewProvider: FC = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<AddOperatorGradeDto>()


  return (
    <ListViewContext.Provider
      value={{
        itemIdForUpdate,
        setItemIdForUpdate,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}

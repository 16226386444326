import { KTSVG } from '../../../../../../_metronic/helpers'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { BurningListFilter } from './BurningLitsFilter'
import {useListView} from '../../core/ListViewProvider'

const UsersListHeader = () => {
  const {refetch} = useQueryResponse()
  const {setItemIdForUpdate} = useListView()
  const openAddUserModal = () => {
    setItemIdForUpdate(null)
  }

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar' style={{width: '100%'}}>
        <button type='button' className='btn btn-primary' style={{marginBottom: '20px'}} onClick={openAddUserModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Add Burning Items
        </button>
        <div className='d-flex justify-content-between' data-kt-user-table-toolbar='base' style={{width: '100%'}}>
          <BurningListFilter />
          <button type='button' className='btn btn-primary' onClick={refetch}>
            Refresh
          </button>
        </div>
      </div>
    </div>
  )
}

export {UsersListHeader}

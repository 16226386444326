import { useMemo } from "react"
import { usersColumns } from "./failedEvents/table/columns/_columns_3"
import { FailedEventsWrapper } from "./failedEvents/FailedEventsPageList"

const FailedEventsPage = () => {
  const columns = useMemo(() => usersColumns, []);

  return <FailedEventsWrapper columns={columns} />
}

export default FailedEventsPage
import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../_metronic/helpers'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {useQueryResponse} from '../core/QueryResponseProvider'

import { UsersListLoading } from '../../../operators/users-list/components/loading/UsersListLoading'
import { getOperatorList } from '../../../operators/users-list/core/_requests'
import { createBurningItem, updateBurningItem } from '../core/_requests'
import { getError } from '../../../../../utils/utils'

export interface burningItem {
  burningItemForeignId: string;
  title: string;
  description: string;
  iconUrl: string;
  usdPrice: bigint;
  isActive: boolean;
}

type Props = {
  burningItem: any
}

enum ShowResultEnum {
  INIT = 'INIT',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

const editUserSchema = Yup.object().shape({
  operatorId: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
    .required('Operator ID is required'),
  title: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
    .required('Title is required'),
  description: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
    .required('Description is required'),
  iconUrl: Yup.string()
  .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
    )
    .required('Icon Url is required'),
  usdPrice: Yup.string()
    .required('USD Price is required'),
  burningItemForeignId: Yup.string()
    .required('burningItemForeignId is required'),
})

const BurninngItemEditModalForm: FC<Props> = ({burningItem}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [createError, setCreateError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [operatorForEdit] = useState<any>({
    operatorId: burningItem?.burningItemForeignId || '',
    title: burningItem?.title || '',
    description: burningItem?.description || '',
    isActive: burningItem?.isActive,
    usdPrice: burningItem?.usdPrice || 1,
    iconUrl: burningItem?.iconUrl || '',
    burningItemForeignId: burningItem?.burningItemForeignId || '',
  })
  const [hashCreated, setHashCreated] = useState<string>()
  const [showResult, setShowResult] = useState<ShowResultEnum>(ShowResultEnum.INIT)

  const formik = useFormik({
    initialValues: operatorForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setCreateError('')
      setSubmitting(true)
      try {
        if (burningItem?.id) {
          const data = await updateBurningItem({
            id: burningItem?.id,
            title: values.title,
            description: values.description,
            // burningItemForeignId: new Date(),
            usdPrice: values.usdPrice,
            iconUrl: values.iconUrl,
          })

          if (data.txHash) {
            setHashCreated(data.txHash)
            setShowResult(ShowResultEnum.SUCCESS)
            setTimeout(() => cancel(true), 5000)
          }
        } else {
          const data = await createBurningItem({
            title: values.title,
            description: values.description,
            isActive: values.isActive,
            operatorId: values.operatorId,
            burningItemForeignId: values.burningItemForeignId,
            usdPrice: values.usdPrice,
            iconUrl: values.iconUrl,
          })

          if (data.hash || data.txHash) {
            setHashCreated(data.hash || data.txHash)
            setShowResult(ShowResultEnum.SUCCESS)
            setTimeout(() => cancel(true), 5000)
          }
        }
      } catch (ex) {
        console.error(ex)
        setShowResult(ShowResultEnum.ERROR)
        //@ts-ignore
        setCreateError(getError(ex))
      } finally {}
    },
  })
  const [operatorList, setOperatorList] = useState([]);

  useEffect(() => {
    const fetchOperatorList = async () => {
      const res = await getOperatorList('')
      setOperatorList(res.data)
    }

    fetchOperatorList()
  }, [])

  return (
    <>
      {((showResult === ShowResultEnum.INIT ||
        showResult === ShowResultEnum.ERROR) && (
        <>
          <form
            id="kt_modal_add_user_form"
            className="form"
            onSubmit={formik.handleSubmit}
            noValidate
          >
            {/* begin::Scroll */}
            <div
              className="d-flex flex-column scroll-y me-n7 pe-7"
              id="kt_modal_add_user_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_user_header"
              data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
              data-kt-scroll-offset="300px"
            >
              {/* begin::Input group */}
              {!burningItem?.id && (
                <div className="mb-10">
                  <label className="form-label fs-6 fw-bold">Operator Id</label>
                  <select
                    id="operatorId"
                    name="operatorId"
                    className="form-select form-select-solid fw-bolder"
                    data-kt-select2="true"
                    data-placeholder="Operator Id"
                    data-allow-clear="true"
                    data-kt-user-table-filter="operatorId"
                    data-hide-search="true"
                    onChange={formik.handleChange}
                    value={formik.values.operatorId}
                  >
                    <option value=""></option>
                    {Array.isArray(operatorList) &&
                      operatorList.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>

                  {formik.touched.operatorId && formik.errors.operatorId && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.operatorId}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">
                  Burning Item ForeignId
                </label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder="burningItemForeignId"
                  {...formik.getFieldProps("burningItemForeignId")}
                  type="text"
                  name="burningItemForeignId"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.burningItemForeignId && formik.errors.burningItemForeignId,
                    },
                    {
                      "is-valid":
                        formik.touched.burningItemForeignId &&
                        !formik.errors.burningItemForeignId,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isLoading || burningItem?.id}
                />
                {formik.touched.burningItemForeignId && formik.errors.burningItemForeignId && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.burningItemForeignId}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Title</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder="Title"
                  {...formik.getFieldProps("title")}
                  type="text"
                  name="title"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid": formik.touched.title && formik.errors.title,
                    },
                    {
                      "is-valid": formik.touched.title && !formik.errors.title,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.title && formik.errors.title && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.title}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">
                  Description
                </label>
                {/* end::Label */}
                {/* begin::Input */}
                <input
                  placeholder="Description"
                  {...formik.getFieldProps("description")}
                  type="text"
                  name="description"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.description && formik.errors.description,
                    },
                    {
                      "is-valid":
                        formik.touched.description &&
                        !formik.errors.description,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.description && formik.errors.description && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.description}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Icon Url</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder="Icon Url"
                  {...formik.getFieldProps("iconUrl")}
                  type="text"
                  name="iconUrl"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.iconUrl && formik.errors.iconUrl,
                    },
                    {
                      "is-valid":
                        formik.touched.iconUrl && !formik.errors.iconUrl,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.iconUrl && formik.errors.iconUrl && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.iconUrl}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">USD Price</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder="usdPrice"
                  {...formik.getFieldProps("usdPrice")}
                  type="number"
                  name="usdPrice"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.usdPrice && formik.errors.usdPrice,
                    },
                    {
                      "is-valid":
                        formik.touched.usdPrice && !formik.errors.usdPrice,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting || isLoading}
                />
                {formik.touched.usdPrice && formik.errors.usdPrice && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.usdPrice}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}

              {/* begin::Input group */}
              {!burningItem?.id && (
                <div className="fv-row mb-7">
                  {/* begin::Input */}
                  {/* begin::Label */}
                  <label className="fw-bold fs-6" style={{ marginRight: 20 }}>
                    Activated:{" "}
                  </label>
                  {/* end::Label */}
                  <input
                    className="form-check-input"
                    type="checkbox"
                    {...formik.getFieldProps("isActive")}
                    name="isActive"
                  />
                  {/* end::Input */}
                </div>
              )}
              {/* end::Input group */}
            </div>
            {/* end::Scroll */}

            {createError && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert" style={{ color: "#f44336" }}>
                    {createError}
                  </span>
                </div>
              </div>
            )}

            {/* begin::Actions */}
            <div className="text-center pt-15">
              <button
                type="reset"
                onClick={() => cancel()}
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                disabled={formik.isSubmitting || isLoading}
              >
                Discard
              </button>

              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  isLoading ||
                  formik.isSubmitting ||
                  !formik.isValid ||
                  !formik.touched
                }
              >
                <span className="indicator-label">Submit</span>
                {(formik.isSubmitting || isLoading) && (
                  <span className="indicator-progress">
                    Please wait...{" "}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Actions */}
          </form>
          {(formik.isSubmitting || isLoading) && <UsersListLoading />}
        </>
      )) ||
        (showResult === ShowResultEnum.SUCCESS && (
          <div style={{ textAlign: "center" }}>
            <h4>Hash created</h4>
            {hashCreated && (
              <a
                target="_blank"
                href={`${process.env.REACT_APP_HASH_URL + hashCreated}`}
                rel="noreferrer"
              >
                {hashCreated}
              </a>
            )}
          </div>
        ))}
    </>
  );
}

export {BurninngItemEditModalForm}

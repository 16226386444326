/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  serializeObjectToQueryString,
  stringifyRequestQuery,
} from '../../../../../_metronic/helpers'
import {getResourcesList} from './_requests'
import {OperatorQueryResponse, User} from './_models'
import {useQueryRequest} from './QueryRequestProvider'
import { getError, useQueryParams } from '../../../../../utils/utils'
import { useLocation } from 'react-router-dom'
import { defaultLimit } from '../../../../components/Table/components/pagination/UsersListPagination'
import { useErrorHandler } from '../../../../../hooks/useErrorsHandler'

const QueryResponseContext = createResponseContext<User>(initialQueryResponse)
const QueryResponseProvider: FC = ({children}) => {
  const {state} = useQueryRequest()
  const {operatorId, page, limit} = useQueryParams()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [response, setResponse] = useState<OperatorQueryResponse | undefined>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [total, setTotal] = useState<number>(0)

  const {
    isFetching,
    refetch,
    data: result,
    error
  } = useQuery(
    `get-resources`,
    async () => {    
      setErrorMessage('')  
      const newQuery = serializeObjectToQueryString({operatorId, limit: limit || defaultLimit, page: page || 1});

      return await getResourcesList(newQuery)
    },
    {cacheTime: 0, retry: false, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    setResponse(result)
  }, [result])
  useErrorHandler(error, setErrorMessage, setResponse)
  const location = useLocation();

  useEffect(() => {
    refetch()
  }, [location]);

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query, total: result?.total, errorMessage}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponsePagination = () => {
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }

  const {response} = useQueryResponse()
  if (!response || !response.payload || !response.payload.pagination) {
    return defaultPaginationState
  }

  return response.payload.pagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}

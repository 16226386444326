import { createSearchParams, useNavigate } from "react-router-dom";
import { pickBy, identity } from 'lodash'

export const useNavigateWithSearchParams = () => {
    const navigate = useNavigate()
    
    const navigateWithSearchParams = (values: any, pathname: string) => {
      //Object.keys(values).forEach((key: string) => values[key] === '' ? delete values[key] : {});
      values = pickBy(values, identity)
      
      navigate({
          pathname,
          search: `?${createSearchParams(values)}`,
      });
    }

  return { navigateWithSearchParams }
};
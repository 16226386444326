import { DashboardData, DashboardDataStatsNames } from "./DashboardWrapper";

export const getDataset = (dashboardData: DashboardData, key: DashboardDataStatsNames) => {
    return Array.isArray(dashboardData?.history)
    ?  dashboardData.history.map((item) => item.value[key])
    : [];
}

export const getLabels = (dashboardData: DashboardData) => {
    return Array.isArray(dashboardData?.history)
    ?  dashboardData.history.map((item) => item.date)
    : [];
}

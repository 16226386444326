import { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { addResource, updateResource } from "../../core/_requests";
import clsx from "clsx";
import { isNotEmpty } from "../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { UsersListLoading } from "../loading/UsersListLoading";
import { getError } from "../../../../../../utils/utils";
import { UpdateOperatorResourceDto } from "../../core/_models";
import { getOperatorList } from "../../../../operators/users-list/core/_requests";

const editUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
});

const ResourcesEditModalForm: FC = () => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [createError, setCreateError] = useState("");

  const [valueForEdit] = useState<UpdateOperatorResourceDto>({
    operatorId: itemIdForUpdate?.operatorId || "",
    id: itemIdForUpdate?.id || "",
    name: itemIdForUpdate?.name || "",
    foreignResourceId: itemIdForUpdate?.foreignResourceId || "",
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const formik = useFormik({
    initialValues: valueForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setCreateError("");
      setSubmitting(true);
      try {
        if (isNotEmpty(itemIdForUpdate?.name)) {
          await updateResource(values.id, values);
        } else {
          await addResource({
            operatorId: values.operatorId,
            foreignResourceId: values.foreignResourceId,
            name: values.name,
          });
        }
        setSubmitting(true);
        cancel(true);
      } catch (ex) {
        console.error(ex);
        //@ts-ignore
        setCreateError(getError(ex));
      }
    },
  });

  const [operatorList, setOperatorList] = useState([]);

  useEffect(() => {
    const fetchOperatorList = async () => {
      const res = await getOperatorList('')
      setOperatorList(res.data)
    }

    fetchOperatorList()
  }, [])

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >

                        {/* begin::Input group */}
                        {!itemIdForUpdate?.id && (
                <div className="mb-10">
                  <label className="form-label fs-6 fw-bold">Operator Id</label>
                  <select
                    id="operatorId"
                    name="operatorId"
                    className="form-select form-select-solid fw-bolder"
                    data-kt-select2="true"
                    data-placeholder="Operator Id"
                    data-allow-clear="true"
                    data-kt-user-table-filter="operatorId"
                    data-hide-search="true"
                    onChange={formik.handleChange}
                    value={formik.values.operatorId}
                  >
                    <option value=""></option>
                    {Array.isArray(operatorList) &&
                      operatorList.map(({ id, name }) => (
                        <option key={id} value={id}>
                          {name}
                        </option>
                      ))}
                  </select>

                  {formik.touched.operatorId && formik.errors.operatorId && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        <span role="alert">{formik.errors.operatorId}</span>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Name"
              {...formik.getFieldProps("name")}
              type="text"
              name="name"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.name && formik.errors.name },
                {
                  "is-valid": formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">
              Foreign Resource Id
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="foreignResourceId"
              {...formik.getFieldProps("foreignResourceId")}
              type="text"
              name="foreignResourceId"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {createError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "#f44336" }}>
                {createError}
              </span>
            </div>
          </div>
        )}

        {/* begin::Actions */}
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className="indicator-label">Submit</span>
            {formik.isSubmitting && (
              <span className="indicator-progress">
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  );
};

export { ResourcesEditModalForm };

import {FC} from 'react'
import { WebhookLogginItem } from '../core/_models'

// export interface webhookInfo {
//   uuid: string,
//   requestBody: string
//   requestHeader: string,
//   responseBody: string,
//   responseHeader: string,
// }

type Props = {
  webhookInfo: WebhookLogginItem
}

const WebhooksModalForm: FC<Props> = ({webhookInfo}) => {

  return (
    <>
      <div>
        <label className='fw-bold fs-6 mb-2'>Request body</label>
        <div>
          {webhookInfo?.request_body}
        </div>
      </div>

      <div>
        <label className='fw-bold fs-6 mb-2'>Request header</label>
        <div>
          {webhookInfo?.request_header}
        </div>
      </div>

      <div>
        <label className='fw-bold fs-6 mb-2'>Response body</label>
        <div>
          {webhookInfo?.response_body}
        </div>
      </div>

      <div>
        <label className='fw-bold fs-6 mb-2'>Response header</label>
        <div>
          {webhookInfo?.response_header}
        </div>
      </div>
    </>
  )
}

export {WebhooksModalForm}

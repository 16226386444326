import { useMemo } from "react"
import { Column } from "react-table";
import { BatchesListWrapper } from "./batches-list/BatchesList";
import { Batch } from "./batches-list/core/_models";
import { usersColumns } from "./batches-list/table/columns/_columns_3";


const BatchesPage = () => {
  //@ts-ignore
  const columns = useMemo((): Column<Batch>[]  => usersColumns, []);
  return  <BatchesListWrapper columns={columns} />
}

export default BatchesPage
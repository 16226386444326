import {useEffect, useMemo} from 'react'
import {useTable, ColumnInstance, Row, Column} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponse, useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {KTCardBody} from '../../../../../_metronic/helpers'
import { Grade } from './columns/_columns_3'
import { UsersListPagination } from '../../../../components/Table/components/pagination/UsersListPagination'
//import { CustomRow } from '../../../events/events-list/table/columns/CustomRow'

const UsersTable = ({columns}: {columns: ReadonlyArray<Column<Grade>>}) => {
  const users = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => users, [users])
  const {total, errorMessage} = useQueryResponse()
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    //@ts-ignore
    data,
  })

  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<Grade>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<Grade>, i) => {
                prepareRow(row)
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />
              })
            ) : (
              <tr>
                <td colSpan={7}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    {errorMessage ? <span style={{color: '#f44336'}}>{errorMessage}</span> : 'No matching records found'}
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* <UsersListPagination isLoading={isLoading} pathname="/translation" total={total}/> */}
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  )
}

export {UsersTable}

import Cookies from "js-cookie";
import { authInstance } from "../../../auth/core/_requests";
import {
  AddTranslationDto,
  UpdateTranslationeDto,
} from "./_models";

export const getResourcesList = (query: any): any => {
  return authInstance
    .get(
      `${process.env.REACT_APP_API_URL}/language/get-all-translations`,
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    )
    .then((res: any) => {
      return { ...res, data: res.data.languages, total: res.data.total };
    });
};

export const addTranslation = (resource: AddTranslationDto) => {
  return authInstance
    .post(
      `language/create-translation`,
      convertDataToApiSchema({ id: "", ...resource }),
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    )
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.total };
    });
};

export const updateResource = (item: UpdateTranslationeDto) => {
  return authInstance
    .post(`language/update-translation`, convertDataToApiSchema(item), {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.total };
    });
};

const convertDataToApiSchema = (data: UpdateTranslationeDto) => {
  const langKeys = ["en", "et", "lv", "es", "pt", "fr", "el", "ja", "de"];

  let apiSchema: {
    id?: number;
    key: string;
    translations?: { lang: string; value: string }[];
  } = {
    key: data.key,
    translations: [],
  };

  if (data?.id) {
    apiSchema.id = +data?.id;
  }

  Object.entries(data).forEach(([key, value]: string[]) => {
    if (langKeys.includes(key)) {
      //@ts-ignore
      apiSchema.translations.push({ lang: key, value });
    }
  });

  return apiSchema;
};

import { useMemo } from "react"
import {QueryRequestProvider} from './webhooks-logging-page/core/QueryRequestProvider'
import {QueryResponseProvider} from './webhooks-logging-page/core/QueryResponseProvider'
import {WebhooksListHeader} from './webhooks-logging-page/components/header/WebhooksListHeader'
import {WebhooksLoggingTable} from './webhooks-logging-page/table/WebhooksLoggingTable'
import { BurningItemsColumns } from "./webhooks-logging-page/table/columns/_columns_3";
import { KTCard } from "../../../_metronic/helpers"
import { WebhookLogginItem } from "./webhooks-logging-page/core/_models"
import { Column } from "react-table"
import { ListViewProvider } from "./webhooks-logging-page/core/ListViewProvider"


const WebhooksLoggingPage = () => {
  //@ts-ignore
  const columns = useMemo((): Column<WebhookLogginItem>[] => BurningItemsColumns, []);
  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <KTCard>
            <WebhooksListHeader />
            <WebhooksLoggingTable columns={columns} />
          </KTCard>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
)}

export default WebhooksLoggingPage
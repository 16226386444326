import { useMemo } from "react"
import { PageLink, PageTitle } from "../../../_metronic/layout/core"
import { usersColumns } from "./events-list/table/columns/_columns_1"
import { UsersListWrapper } from "../operators/users-list/UsersList"
import { EventsListWrapper } from "./events-list/EventsList"

const EventsPage = () => {
  const columns = useMemo(() => usersColumns, []);

  return (
    <>
      <EventsListWrapper columns={columns} />
    </>
  )
}

export default EventsPage
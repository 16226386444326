import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/ResourcesTable'
import {KTCard} from '../../../../_metronic/helpers'
import { Column } from 'react-table'
import { EventFailedType } from './table/columns/_columns_3'

const FailedEventsList = ({columns}: {columns: ReadonlyArray<Column<EventFailedType>>}) => {
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable columns={columns} />
      </KTCard>
    </>
  )
}

const FailedEventsWrapper = ({columns}: {columns: ReadonlyArray<Column<EventFailedType>>}) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <FailedEventsList columns={columns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {FailedEventsWrapper}
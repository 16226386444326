import axios from 'axios'
import Cookies from 'js-cookie'
import { EventType } from '../table/columns/_columns_1'
import { OperatorQueryResponse } from './_models'


export const getEventsList = (query: string): Promise<{data: EventType[], total: number}> => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/events/get-events?${query}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
      }
    })
    .then((res: any) => {
      return {...res, data: res.data.result, total: res.data.total}
    })
}

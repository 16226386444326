import Cookies from 'js-cookie'
import { authInstance } from '../../../auth/core/_requests'
import {AddOperatorResourceDto, OperatorQueryResponse} from './_models'

export const getResourcesList = (query: any): any => {
  return authInstance
    .get(`${process.env.REACT_APP_API_URL}/admin/get-resources?${query}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
      }
    })
    .then((res: any) => {
      return {...res, data: res.data.result, total: res.data.total}
    })
}

export const addResource = (resource: AddOperatorResourceDto) => {
  return authInstance
  .post(`admin/add-resource`, resource, {
    headers: {
      Authorization: 'Bearer ' + Cookies.get('token'),
    }
  })
  .then((res: any) => {
    return {...res, data: res.data.result, total: res.data.total}
  })
}

export const updateResource = (id: string, resource: AddOperatorResourceDto) => {
  return authInstance
  .post(`admin/edit-resource`, {id, ...resource}, {
    headers: {
      Authorization: 'Bearer ' + Cookies.get('token'),
    }
  })
  .then((res: any) => {
    return {...res, data: res.data.result, total: res.data.total}
  })
}


import axios from "axios";
import Cookies from "js-cookie";
import { AuthModel, UserModel } from "./_models";

export type TokenInfo = {
  token: string;
  expiresIn: string;
};

export const API_URL: string | undefined = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${API_URL}/admin/login`;
export const REGISTER_URL = `${API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`;

export const authInstance = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: "Bearer " + Cookies.get("token"),
  },
});

export function login(email: string, password: string, recaptcha: string) {
  const headers: { [key: string]: string } = {};
  headers["x-recaptcha"] = recaptcha;

  return axios.post(
    LOGIN_URL,
    {
      email,
      password,
    },
    { headers }
  );
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  });
}

export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  });
}

// export function getUserByToken(token: string) {
//   return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
//     api_token: token,
//   })
// }

import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../app/modules/auth"

export const ANAUTHORIZED_CODE = 403;

export const useErrorHandler = (
    error: {message: string, status: number} | unknown,
    setErrorMessage: (message: string) => void,
    setResponse: (data: any) => void,
) => {
    const navigate = useNavigate()
    const {logout} = useAuth()
  
    useEffect(() => {
      if (error) {
        //@ts-ignore
        const { message, status } = error?.response?.data
        if (status === ANAUTHORIZED_CODE) {
          logout()
          navigate({pathname: '/auth'})
        }
        setErrorMessage(typeof message === 'string' ? message : message.join(','))
        setResponse({data: []})
      }
    }, [error])
}
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { WebhooksListFilter } from './WebhooksLitsFilter'

const WebhooksListHeader = () => {
  const {refetch} = useQueryResponse()

  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar' style={{width: '100%'}}>
        <div className='d-flex justify-content-between' data-kt-user-table-toolbar='base' style={{width: '100%'}}>
          <WebhooksListFilter />
          <button type='button' className='btn btn-primary' onClick={refetch}>
            Refresh
          </button>
        </div>
      </div>
    </div>
  )
}

export {WebhooksListHeader}

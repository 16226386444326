import Cookies from "js-cookie";
import { authInstance } from "../../../auth/core/_requests";
import { AddOperatorGradeDto } from "./_models";

const getGradesList = (query: any): any => {
  return authInstance
    .get(`admin/get-grades?${query}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.total };
    });
};

const createGrade = (grade: AddOperatorGradeDto) => {
  return authInstance
    .post(`admin/add-grade`, grade, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.total };
    });
};

const updateGrade = (id: string, grade: any) => {
  return authInstance
    .post(
      `admin/edit-grade`,
      { id, ...grade },
      {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      }
    )
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.total };
    });
};

const getValidationSchema = () => {
  return authInstance.get(
    `${process.env.REACT_APP_API_URL}/operator/get-validation-info`,
    {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    }
  );
};

export { getGradesList, createGrade, updateGrade, getValidationSchema };

import axios from 'axios'
import Cookies from 'js-cookie'
import { useState } from 'react'
import {Column, HeaderProps} from 'react-table'
import { TableCustomHeader } from '../../../../../components/Table/table/columns/TableCustomHeader'
import { UniversalContent } from '../../../../../components/Table/table/columns/UniversalProps'
import Toggle from '../../components/header/toggle/Toggle'
import { useListView } from '../../core/ListViewProvider'
import { BurningItem } from '../../core/_models'
import pencil from '../../../../../../icons/pencil.svg'

const BurningItemsColumns: ReadonlyArray<Column<BurningItem>> = [
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='id' className='min-w-120px' />,
    accessor: 'id',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='OPERATOR ID' className='min-w-150px' />,
    accessor: 'operatorId',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='FOREIGN ID' className='min-w-250px' />,
    accessor: 'burningItemForeignId',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='TITLE' className='min-w-250px' />,
    accessor: 'title',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='DESCRIPTION' className='min-w-250px' />,
    accessor: 'description',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='ICON URL' className='min-w-250px' />,
    accessor: 'iconUrl',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='USD PRICE' className='min-w-200px' />,
    accessor: 'usdPrice',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader tableProps={props} title='IS ACTIVE' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const [loading, setLoading] = useState<boolean>(false)
      const isActive = props.data[props.row.index].isActive
      const [status, setStatus] = useState<boolean>(isActive)
      const body = {
        operatorId: props.data[props.row.index].operatorId,
        burningItemId: props.data[props.row.index].id
      }

      const updateStatus = async (): Promise<any> => {
        setLoading(true);
        if (status) {
          await axios.post(`${process.env.REACT_APP_API_URL}/admin/deactivate-burning-item`,
            body,
            {
              headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
              }
            }
          )
          .then(() => {
            setStatus(!status)
          })
          .finally(() => {
            setLoading(false)
          })
        } else {
          await axios.post(`${process.env.REACT_APP_API_URL}/admin/activate-burning-item`,
            body,
            {
              headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
              }
            }
          )
          .then(() => {
            setStatus(!status)
          })
          .finally(() => {
            setLoading(false)
          })
        }
      }

      const content = (
          <Toggle state={status} isLoading={loading} onToggleChange={updateStatus}/>);
        return content
      },
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader tableProps={props} title='UPDATE' className='text-end min-w-100px' />
    ),
    id: 'actions2',
    Cell: ({...props}) => {
      const {setItemIdForUpdate} = useListView()

      const openWebhooksInfoModal = ()=> {
        setItemIdForUpdate(props.data[props.row.id])
      }

        return (
          <div onClick={openWebhooksInfoModal} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <img alt='' src={pencil} style={{marginRight: 20, cursor: 'pointer'}}/>
          </div>
        )
      },
  },
]

export {BurningItemsColumns}



import { FC, useState, createContext, useContext } from 'react'
import { EventFailedBodyType } from '../table/columns/_columns_3'

const ListViewContext = createContext<{itemIdForUpdate?: EventFailedBodyType, setItemIdForUpdate?: any}>({})

const ListViewProvider: FC = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<EventFailedBodyType>()

  return (
    <ListViewContext.Provider
      value={{
        itemIdForUpdate,
        setItemIdForUpdate,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}

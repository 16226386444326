import { Column } from "react-table";
import { formatPriceNumberWithCurrency } from "../../../../../../utils/utils";
import { Tooltip, TooltipPlacement } from "../../../../../components/Table/components/tooltip/Tooltip";
import { UserCustomHeader } from "./UserCustomHeader";

export type EventType = {
  id: string;
  // foreignId: string,
  // operatorResourceId: string
  userId: string;
  operatorGradeId: string;
  createdAt: Date;
  foreignResourceId: string;
  mintAmount: number;
  amount: string;
  poolAmount: number;
};

const usersColumns: ReadonlyArray<Column<EventType>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="id" className="min-w-50px" />
    ),
    accessor: "id",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="user_id"
        className="min-w-190px"
      />
    ),
    accessor: "userId",
  },

  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="grade_id"
        className="min-w-190px"
      />
    ),
    accessor: "operatorGradeId",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Foreign Resource Id"
        className="min-w-190px"
      />
    ),
    accessor: "foreignResourceId",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Created At"
        className="min-w-250px"
      />
    ),
    accessor: "createdAt",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Amount (USD)"
        className="min-w-125px"
      />
    ),
    accessor: "amount",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Mint Amount (FRWD)"
        className="min-w-125px"
      />
    ),
    accessor: "mintAmount",
    Cell: ({ ...props }) => {
      //@ts-ignore
      Number.prototype.noExponents = function() {
        var data = String(this).split(/[eE]/);
        if (data.length == 1) return data[0];
      
        var z = '',
          sign = this < 0 ? '-' : '',
          str = data[0].replace('.', ''),
          mag = Number(data[1]) + 1;
      
        if (mag < 0) {
          z = sign + '0.';
          while (mag++) z += '0';
          return z + str.replace(/^\-/, '');
        }
        mag -= str.length;
        while (mag--) z += '0';
        return str + z;
      }
      //@ts-ignore
      const value = props.data[props.row.id].mintAmount;

      const content = <div>{(value / 10e18).toFixed(2)}</div>;
      return (
        <Tooltip
        padding={'15px'}
          value={formatPriceNumberWithCurrency({
            number: String(value.noExponents()),
            cutZeros: true,
          })}
          placement={TooltipPlacement.TOP}
        >
          {content}
        </Tooltip>
      );
    },
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Pool Amount (USDT)"
        className="min-w-125px"
      />
    ),
    accessor: "poolAmount",
  },
];

export { usersColumns };

import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { MenuComponent } from "../../../../../../_metronic/assets/ts/components";
import { initialQueryState, KTSVG } from "../../../../../../_metronic/helpers";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import clsx from "clsx";
import { useQueryParams } from "../../../../../../utils/utils";
import { BatchStatusEnum } from "../../core/_models";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useEffectSkipFirst } from "../../../../../../hooks/useEffectSkipFirst";
import { DateRange } from "react-date-range";
import { useNavigateWithSearchParams } from "../../../../../../hooks/useNavigateWithSearchParams";

export type PickerRangeType = {
  selection: { endDate: Date; startDate: Date; key: "selection" };
};

const UsersListFilter = () => {
  const queryParams = useQueryParams();
  const { updateState } = useQueryRequest();
  const { isLoading } = useQueryResponse();
  const { navigateWithSearchParams } = useNavigateWithSearchParams();

  const formik = useFormik({
    initialValues: {
      batchId: "",
      txHash: "",
      dateMin: "",
      dateMax: "",
      status: "",
    },

    onSubmit: (values) => {
      navigateWithSearchParams(values, "/batches");
    },
  });

  useEffect(() => {
    MenuComponent.reinitialization();
    const { batchId, txHash, dateMin, dateMax, status } = queryParams;

    formik.setFieldValue("batchId", batchId || "");
    formik.setFieldValue("txHash", txHash || "");
    dateMin && setStartDate(new Date(dateMin));
    dateMax && setEndDate(new Date(dateMax));
    formik.setFieldValue("status", status || "");
  }, []);

  const [documentHeight, setDocumentHeight] = useState<number>();

  useEffect(() => {
    const body = document.body,
      html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    setDocumentHeight(height);
  });

  const formReset = () => {
    formik.resetForm();
    setStartDate(new Date());
    setEndDate(new Date());

    navigateWithSearchParams({}, "/batches");
    updateState({
      filter: {
        batchId: "",
        txHash: "",
        status: "",
        dateMin: "",
        dateMax: "",
      },
      ...initialQueryState,
    });
  };

  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());

  useEffectSkipFirst(() => {
    formik.setFieldValue("dateMax", moment(endDate).format());
    formik.setFieldValue("dateMin", moment(startDate).format());

    setState([{ startDate, endDate, key: "selection" }]);
  }, [endDate, startDate]);

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const setTimeSelection = (item: PickerRangeType) => {
    let { endDate, startDate } = item.selection;

    startDate = new Date(startDate);
    endDate = new Date(endDate.setHours(endDate.getHours() + 23, endDate.getMinutes() + 59, endDate.getSeconds() + 59));

    setStartDate(startDate);
    setEndDate(endDate);
    setState([item.selection]);
  };

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type="button"
        className="btn btn-light-primary me-3"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >
        <KTSVG
          path="/media/icons/duotune/general/gen031.svg"
          className="svg-icon-2"
        />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div
        className="menu menu-sub menu-sub-dropdown w-350px w-md-375px"
        data-kt-menu="true"
      >
        <div
          style={{
            height:
              documentHeight && documentHeight - 139 < 750
                ? "calc(100vh - 139px)"
                : "auto",
            overflow: "scroll",
          }}
        >
          {/* begin::Header */}
          <div className="px-7 py-5">
            <div className="fs-5 text-dark fw-bolder">Filter Options</div>
          </div>
          {/* end::Header */}

          {/* begin::Separator */}
          <div className="separator border-gray-200"></div>
          {/* end::Separator */}

          {/* begin::Content */}
          <div className="px-7 py-5" data-kt-user-table-filter="form">
            <form onSubmit={formik.handleSubmit}>
              <label
                className="form-label fs-6 fw-bolder text-dark"
                htmlFor="batchId"
              >
                batchId
              </label>
              <input
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                id="batchId"
                name="batchId"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.batchId}
              />

              <label
                className="form-label fs-6 fw-bolder text-dark"
                htmlFor="txHash"
              >
                txHash
              </label>
              <input
                className={clsx(
                  "form-control form-control-lg form-control-solid"
                )}
                id="txHash"
                name="txHash"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.txHash}
              />

              <label
                className="form-label fs-6 fw-bolder text-dark"
                htmlFor="txHash"
              >
                Batch Status
              </label>
              <select
                id="status"
                name="status"
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select Status"
                data-allow-clear="true"
                data-kt-user-table-filter="status"
                data-hide-search="true"
                onChange={formik.handleChange}
                value={formik.values.status}
              >
                <option value=""></option>
                <option value={BatchStatusEnum.COMPLETED}>
                  {BatchStatusEnum.COMPLETED}
                </option>
                <option value={BatchStatusEnum.CREATED}>
                  {BatchStatusEnum.CREATED}
                </option>
                <option value={BatchStatusEnum.FAILED}>
                  {BatchStatusEnum.FAILED}
                </option>
                <option value={BatchStatusEnum.PROCESSING}>
                  {BatchStatusEnum.PROCESSING}
                </option>
                <option value={BatchStatusEnum.SENT}>
                  {BatchStatusEnum.SENT}
                </option>
              </select>
              <label
                className="form-label fs-6 fw-bolder text-dark"
                htmlFor="timeRange"
              >
                Time Range
              </label>
              <DateRange
                editableDateInputs={true}
                /* @ts-ignore */
                onChange={setTimeSelection}
                moveRangeOnFirstSelection={false}
                ranges={state}
                
              />

              {/* begin::Actions */}
              <div
                className="d-flex justify-content-end"
                style={{ marginTop: 25 }}
              >
                <button
                  type="button"
                  disabled={isLoading}
                  onClick={formReset}
                  className="btn btn-light btn-active-light-primary fw-bold me-2 px-6"
                  data-kt-menu-dismiss="true"
                  data-kt-user-table-filter="reset"
                >
                  Reset
                </button>
                <button
                  disabled={isLoading}
                  type="submit"
                  className="btn btn-primary fw-bold px-6"
                  data-kt-menu-dismiss="true"
                  data-kt-user-table-filter="filter"
                >
                  Apply
                </button>
              </div>
              {/* end::Actions */}
            </form>
          </div>
          {/* end::Content */}
        </div>
      </div>
      {/* end::SubMenu */}
    </>
  );
};

export { UsersListFilter };

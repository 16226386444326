import React from "react";
import { Bar } from "react-chartjs-2";
import { DashboardData, DashboardDataStatsNames } from "./DashboardWrapper";
import { getDataset, getLabels } from "./utils";

const options = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: true,
            text: 'Total Events',
        },
        // datalabels: {
        //     anchor: 'end',
        //     align: 'top',
        //     formatter: Math.round,
        //     font: {
        //         weight: 'bold'
        //     }
        // }
    },
    scales: {
        y: {
            title: {
                text: 'Events count',
                display: true,
            },
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
            color: "rgba(75,192,192,1)",
        },
        y1: {
            title: {
                text: 'FWD',
                display: true,
            },
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            grid: {
                drawOnChartArea: false,
            },
            color:  "#742774",
        },
    },
};

const EventsStatsChart = ({dashboardData}: { dashboardData:  DashboardData}) => {
    const data = {
        labels: getLabels(dashboardData),
        datasets: [
            {
                label: "Total Events",
                data: getDataset(dashboardData, DashboardDataStatsNames.totalEvents),
                fill: false,
                backgroundColor: "rgba(75,192,192,0.2)",
                borderColor: "rgba(75,192,192,1)",
                yAxisID: 'y',
            },
            {
                label: "Total Amount",
                data: getDataset(dashboardData, DashboardDataStatsNames.totalAmount),
                fill: false,
                type: 'line',
                borderColor: "#742774",
                yAxisID: 'y1',
            }
        ]
    };

    return (
        // because invalid prop
        //@ts-ignore
        <><Bar data={data} options={options}/></>
    );
};

export { EventsStatsChart };

import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {OperatorRootUpdate} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {UsersListLoading} from '../components/loading/UsersListLoading'
import {createOperator, updateOperator} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  user?: OperatorRootUpdate
}

const editUserSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
    .required('Name is required'),
  address: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
})

const UserEditModalForm: FC<Props> = ({user}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [createError, setCreateError] = useState('');

  const [operatorForEdit] = useState<OperatorRootUpdate>({
    id: user?.id || 0,
    name: user?.name || '',
    address: user?.address || '',
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: operatorForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setCreateError('')
      setSubmitting(true)
      try {
        if (user?.id) {
          if (values.id && values.address) {
            await updateOperator({
              operatorId: values.id,
              address: values.address
            })
          }
        } else {
          await createOperator({
            name: values.name
          })
        }
        setSubmitting(true)
        cancel(true)
      } catch (ex) {
        //@ts-ignore
        setCreateError(ex?.response?.data?.message)
      }
    },
  })

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          {!user?.id ? (
            <>
              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Name</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder="Name"
                  {...formik.getFieldProps("name")}
                  type="text"
                  name="name"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    { "is-invalid": formik.touched.name && formik.errors.name },
                    {
                      "is-valid": formik.touched.name && !formik.errors.name,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.name}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </>
          ) : (
            <>
              {/* begin::Input group */}
              <div className="fv-row mb-7">
                {/* begin::Label */}
                <label className="required fw-bold fs-6 mb-2">Address</label>
                {/* end::Label */}

                {/* begin::Input */}
                <input
                  placeholder="Address"
                  {...formik.getFieldProps("address")}
                  type="text"
                  name="address"
                  className={clsx(
                    "form-control form-control-solid mb-3 mb-lg-0",
                    {
                      "is-invalid":
                        formik.touched.address && formik.errors.address,
                    },
                    {
                      "is-valid":
                        formik.touched.address && !formik.errors.address,
                    }
                  )}
                  autoComplete="off"
                  disabled={formik.isSubmitting}
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">{formik.errors.address}</span>
                    </div>
                  </div>
                )}
                {/* end::Input */}
              </div>
              {/* end::Input group */}
            </>
          )}
        </div>
        {/* end::Scroll */}

        {createError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{color: '#f44336'}}>{createError}</span>
            </div>
          </div>
        )}

        {/* begin::Actions */}
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={
              formik.isSubmitting ||
              (user?.id ? !!formik.errors.address : !!formik.errors.name) ||
              !formik.touched
            }
          >
            <span className="indicator-label">Submit</span>
            {formik.isSubmitting && (
              <span className="indicator-progress">
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  );
}

export {UserEditModalForm}

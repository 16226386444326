import React, {useEffect, useState} from 'react'
import { useFormik } from 'formik'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import clsx from 'clsx'
import { useQueryParams } from '../../../../../../utils/utils'
import "react-datepicker/dist/react-datepicker.css";
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { DateRange } from 'react-date-range'
import { useEffectSkipFirst } from '../../../../../../hooks/useEffectSkipFirst'
import moment from 'moment'
import { useNavigateWithSearchParams } from '../../../../../../hooks/useNavigateWithSearchParams'
import { PickerRangeType } from '../../../../batches/batches-list/components/header/UsersListFilter'
import { WebhooksStatusEnum } from '../../core/_models'

const WebhooksListFilter = () => {
  const queryParams = useQueryParams()
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date())
  const {navigateWithSearchParams} = useNavigateWithSearchParams()
  const WebhooksStatusArray = [
    WebhooksStatusEnum.ERR_FR_TOO_MANY_REDIRECTS,
    WebhooksStatusEnum.ERR_BAD_OPTION_VALUE,
    WebhooksStatusEnum.ERR_BAD_OPTION,
    WebhooksStatusEnum.ERR_NETWORK,
    WebhooksStatusEnum.ERR_DEPRECATED,
    WebhooksStatusEnum.ERR_BAD_RESPONSE,
    WebhooksStatusEnum.ERR_BAD_REQUEST,
    WebhooksStatusEnum.ERR_CANCELED,
    WebhooksStatusEnum.ECONNABORTED,
    WebhooksStatusEnum.ETIMEDOUT,
    WebhooksStatusEnum.SUCCESS
  ]
  const [statusNewSelected, setStatusNewSelected] = useState<WebhooksStatusEnum[]>([]);

  const updateStatus = (option: WebhooksStatusEnum) => {
    const index = statusNewSelected?.indexOf(option);
    if (index !== -1) {
      const updatedData = statusNewSelected?.filter((n) => n !== option) 
      setStatusNewSelected(updatedData)
      formik.setFieldValue('statuses', updatedData?.join(',') || '')
    } else {
      const updatedData = [...statusNewSelected, option]
      setStatusNewSelected(updatedData)
      formik.setFieldValue('statuses', updatedData?.join(',') || '')
    }
  }

  const formik = useFormik({
    initialValues: {
      statuses: '',
      webhookUrls: '',
      operatorIds: '',
      dateMin: '',
      dateMax: '',
    },

    onSubmit: values => {
      if (values.dateMax === values.dateMin) {
        //@ts-ignore
        delete values.dateMax
         //@ts-ignore
        delete values.dateMin
      }
      navigateWithSearchParams({...values}, '/webhooks-logging')
    },
  });

  useEffect(() => {
    MenuComponent.reinitialization()
    const { statuses, webhookUrls, operatorIds, dateMin, dateMax } = queryParams;

    formik.setFieldValue('operatorIds', operatorIds || '')
    dateMin && setStartDate(new Date(dateMin) || '')
    dateMax && setEndDate(new Date(dateMax) || '')

    formik.setFieldValue('statuses', statuses || '')
    setStatusNewSelected(statuses?.split(',') || [])

    formik.setFieldValue('webhookUrls', webhookUrls || '')
  }, [])

  const formReset = () => {
    formik.resetForm();
    const resetDate = new Date()
    setStartDate(resetDate)
    setEndDate(resetDate)
    setStatusNewSelected([])

    navigateWithSearchParams({}, '/webhooks-logging')
    updateState({
      filter: {
        operatorIds: '',
        webhookUrls: '',
        statuses: '',
        dateMin: '',
        dateMax: '',
      },
      ...initialQueryState,
    })
  }

  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  useEffectSkipFirst(() => {
    formik.setFieldValue('dateMax', moment.utc(endDate).format())
    formik.setFieldValue('dateMin', moment.utc(startDate).format())
    setState([{ startDate, endDate, key: 'selection' }])
  }, [endDate, startDate])

  const setTimeSelection = (item: PickerRangeType) => {
    const {endDate, startDate} = item.selection
    setStartDate(startDate)
    setEndDate(endDate)
    setState([item.selection])
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-850px w-md-875px' data-kt-menu='true' style={{inset: '0px 0px auto 1300px'}}>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <form onSubmit={formik.handleSubmit}>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex', flexDirection: 'column', width: '45%', marginRight: 20}}>
                <label className='form-label fs-6 fw-bolder text-dark' htmlFor="operatorIds">Operator Id</label>
                <input
                    className={clsx(
                      'form-control form-control-lg form-control-solid')}
                    id="operatorIds"
                    name="operatorIds"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.operatorIds}
                />

                <label className='form-label fs-6 fw-bolder text-dark' htmlFor="operatorIds">Status</label>
                {
                  WebhooksStatusArray.map(option => (
                  <span key={option} className='form-check form-check-custom form-check-solid' style={{marginBottom: 5}}>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value={option || ''}
                      checked={statusNewSelected.includes(option)}
                      onChange={() => updateStatus(option)}
                      style={{marginRight: 10}}
                    />
                    {option}
                  </span>
                  ))
                }
              </div>

              <div style={{display: 'flex', flexDirection: 'column', width: '50%', marginLeft: 20}}>
                <label className='form-label fs-6 fw-bolder text-dark' htmlFor="webhookUrls">Webhook Url</label>
                <input
                    className={clsx(
                      'form-control form-control-lg form-control-solid')}
                    id="webhookUrls"
                    name="webhookUrls"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.webhookUrls}
                />

                <label className='form-label fs-6 fw-bolder text-dark' htmlFor="dataRange">Date Range</label>
                <DateRange
                  editableDateInputs={true}
                  /* @ts-ignore */
                  onChange={setTimeSelection}
                  moveRangeOnFirstSelection={false}
                  ranges={state}
                />

                {/* begin::Actions */}
                <div className='d-flex justify-content-end' style={{marginTop: 25}}>
                  <button
                    type='button'
                    disabled={isLoading}
                    onClick={formReset}
                    className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='reset'
                  >
                    Reset
                  </button>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className='btn btn-primary fw-bold px-6'
                    data-kt-menu-dismiss='true'
                    data-kt-user-table-filter='filter'
                  >
                    Apply
                  </button>
                </div>
                {/* end::Actions */}
              </div>
            </div>
          </form>
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {WebhooksListFilter}

/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  serializeObjectToQueryString,
  stringifyRequestQuery,
} from '../../../../../_metronic/helpers'
import {getBatchesList} from './_requests'
import {useQueryRequest} from './QueryRequestProvider'
import { useLocation } from 'react-router-dom'
import { useQueryParams } from '../../../../../utils/utils'
import { defaultLimit } from '../../../../components/Table/components/pagination/UsersListPagination'
import { useErrorHandler } from '../../../../../hooks/useErrorsHandler'

const QueryResponseContext = createResponseContext<any>(initialQueryResponse)
const QueryResponseProvider: FC = ({children}) => {
  const {state} = useQueryRequest()
  const {txHash, batchId, page, limit, status, dateMin, dateMax} = useQueryParams()
  const location = useLocation();
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [response, setResponse] = useState<any>();
  const [errorMessage, setErrorMessage] = useState<string>('')
  const {
    isFetching,
    refetch,
    data: result,
    error
  } = useQuery(
    `get-batches`,
    async () => {    
      setErrorMessage('')
      return await getBatchesList(serializeObjectToQueryString({batchId, txHash, limit: limit || defaultLimit, page: page || 1, status, dateMin, dateMax}))
    },
    {cacheTime: 0, retry: false, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    setResponse(result)
  }, [result])

  useErrorHandler(error, setErrorMessage, setResponse)

  useEffect(() => {
    refetch()
  }, [location]);

  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query, total: result?.total, errorMessage }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

const useQueryResponseData = () => {
  const {response} = useQueryResponse()
  if (!response) {
    return []
  }

  return response?.data || []
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponseLoading,
}

import {Column, HeaderProps} from 'react-table'
import { TableCustomHeader } from '../../../../../components/Table/table/columns/TableCustomHeader'
import { UniversalContent } from '../../../../../components/Table/table/columns/UniversalProps'
import { useListView } from '../../core/ListViewProvider'

import Toggle from '../../../../BurningItems/burning-items-list/components/header/toggle/Toggle';
import axios from 'axios';
import { useState } from 'react';
import Cookies from 'js-cookie';

const usersColumns: ReadonlyArray<Column<UniversalContent>> = [
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='Name' className='min-w-125px' />,
    accessor: 'name',
  },
  {
    Header: (props) => <TableCustomHeader tableProps={props} title='id' className='min-w-125px' />,
    accessor: 'id',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader tableProps={props} title='Address' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const {setItemIdForUpdate} = useListView()

      const openWebhooksInfoModal = ()=> {
        //@ts-ignore
        setItemIdForUpdate(props.data[props.row.id])
      }

      //@ts-ignore
      const content = !props.data[props.row.id].address
        ? (
          <div onClick={openWebhooksInfoModal} style={{marginRight: 20, cursor: 'pointer', textDecoration: 'underline'}}>
            Add address
          </div>
        ) : (
          <div>
            {/* @ts-ignore */}
            {props.data[props.row.id].address}
          </div>
        );
        return content
      },
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader tableProps={props} title='IS ACTIVE' className='text-end min-w-100px' />
    ),
    id: 'actions_2',
    Cell: ({...props}) => {
      const [loading, setLoading] = useState<boolean>(false)
      //@ts-ignore
      const isActive = props.data[props.row.index].isActive
      const [status, setStatus] = useState<boolean>(isActive)
      const body = {
        //@ts-ignore
        id: props.data[props.row.index].id,
        // operatorId: props.data[props.row.index].operatorId,
        // burningItemId: props.data[props.row.index].id
      }

      const updateStatus = async (): Promise<any> => {
        setLoading(true);



          if (status) {
            await axios.post(`${process.env.REACT_APP_API_URL}/admin/deactivate`,
            body,
            {
              headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
              }
            }
          )
          .then(() => {
            setStatus(!status)
          })
          .finally(() => {
            setLoading(false)
          })
        } else {
          await axios.post(`${process.env.REACT_APP_API_URL}/admin/activate`,
            body,
            {
              headers: {
                Authorization: 'Bearer ' + Cookies.get('token'),
              }
            }
          )
          .then(() => {
            setStatus(!status)
          })
          .finally(() => {
            setLoading(false)
          })
        }
      }

      const content = (
          <Toggle state={status} isLoading={loading} onToggleChange={updateStatus}/>);
        return content
      },
  },
]

export {usersColumns}

import {Column, HeaderProps} from 'react-table'
import { TableCustomHeader } from '../../../../../components/Table/table/columns/TableCustomHeader'
import { UniversalContent } from '../../../../../components/Table/table/columns/UniversalProps'
import { WebhookLogginItem } from '../../core/_models'
import eye from '../../../../../../icons/eye.svg'
import { useListView } from '../../core/ListViewProvider'
import { HeaderWithSort } from '../../../../../components/Table/table/columns/HeaderWithSort'

const BurningItemsColumns: ReadonlyArray<Column<WebhookLogginItem>> = [
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='ID' className='min-w-150px' />,
    accessor: 'id',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='OPERATOR ID' className='min-w-150px' />,
    accessor: 'operator_id',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <HeaderWithSort tableProps={props} title='TIMESTAMP' className='min-w-200px' />,
    accessor: 'timestamp',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='STATUS CODE' className='min-w-150px' />,
    accessor: 'status_code',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => <TableCustomHeader tableProps={props} title='NOTIFICATION URL' className='min-w-250px' />,
    accessor: 'notification_url',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader tableProps={props} title='MORE INFO' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const {setItemIdForUpdate} = useListView()

      const openWebhooksInfoModal = ()=> {

        setItemIdForUpdate(props.data[props.row.id])
      }

      const content = props.data[props.row.id].status_code === null 
        ? (
          <div onClick={openWebhooksInfoModal}>
            <img alt='' src={eye} style={{marginRight: 20, cursor: 'pointer'}}/>
          </div>
        ) : (
          <div onClick={openWebhooksInfoModal}>
            <img alt='' src={eye} style={{marginRight: 20, cursor: 'pointer'}}/>
          </div>
        );
        return content
      },
  },
]

export {BurningItemsColumns}

import {FC, useEffect, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {createGrade, updateGrade} from '../../core/_requests'
import clsx from 'clsx'
import { useListView } from '../../core/ListViewProvider'
import { useQueryResponse } from '../../core/QueryResponseProvider'
import { UsersListLoading } from '../loading/UsersListLoading'
import { getError } from '../../../../../../utils/utils'
import { UpdateOperatorGradeDto } from '../../core/_models'
import { getResourcesList } from '../../../../resources/resouces/core/_requests'

type Props = {
  grade: UpdateOperatorGradeDto
}

const gradeParamsSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(250, 'Maximum 250 symbols')
    .required('Name is required'),
  mintPercentage: Yup.number()
    // .max(0.001, 'Maximum 0.001')
    .required('mintPercentage is required'),
  poolPercentage: Yup.number()
    // .min(0.001, 'Minimum 0.001')
    .required('poolPercentage is required'),
  operatorResourceId: Yup.string()
  .required('Operator Resource Id is required'),
})

const GradeEditModalForm: FC<Props> = ({grade}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [createError, setCreateError] = useState('');

  const [valueForEdit] = useState<UpdateOperatorGradeDto>({
    id: grade?.id || '',
    name: grade?.name || '',
    eventAmount: grade?.eventAmount || 0,
    operatorResourceId: grade?.operatorResourceId || '',
    mintPercentage: grade?.mintPercentage || 0,
    poolPercentage: grade?.poolPercentage || 0,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const [resources, setResources] = useState([]);
  useEffect(() => {
    const fetchResources = async () => {
      const res = await getResourcesList('')
      setResources(res.data)
    }

    fetchResources()
  }, [])

  const formik = useFormik({
    initialValues: valueForEdit,
    validationSchema: gradeParamsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setCreateError('')
      setSubmitting(true)
      try {
        if (grade?.name) {
          await updateGrade(grade.id, 
            {
              id: grade.id,
              name: values.name,
              eventAmount: +values.eventAmount,
              mintPercentage: Number(values.mintPercentage),
              poolPercentage: Number(values.poolPercentage),
            })
        } else {
          await createGrade({
            ...values,
            eventAmount: +values.eventAmount,
            mintPercentage: Number(values.mintPercentage),
            poolPercentage: Number(values.poolPercentage),
          })
        }
        setSubmitting(true)
        cancel(true)
      } catch (ex) {
        //@ts-ignore
        setCreateError(getError(ex))
      }
    },
  })

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">Name</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Name"
              {...formik.getFieldProps("name")}
              type="text"
              name="name"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.name && formik.errors.name },
                {
                  "is-valid": formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {formik.touched.name && formik.errors.name && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">Event Amount</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="eventAmount"
              {...formik.getFieldProps("eventAmount")}
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                {
                  "is-invalid":
                    formik.touched.eventAmount && formik.errors.eventAmount,
                },
                {
                  "is-valid":
                    formik.touched.eventAmount && !formik.errors.eventAmount,
                }
              )}
              name="eventAmount"
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* end::Input */}
            {formik.touched.eventAmount && formik.errors.eventAmount && (
              <div className="fv-plugins-message-container">
                <span role="alert">{formik.errors.eventAmount}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {!grade?.id && (
            <div className="mb-10">
              <label className="form-label fs-6 fw-bold">Operator Resource Id</label>
              <select
                id="operatorResourceId"
                name="operatorResourceId"
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Operator Resource Id"
                data-allow-clear="true"
                data-kt-user-table-filter="operatorResourceId"
                data-hide-search="true"
                onChange={formik.handleChange}
                value={formik.values.operatorResourceId}
              >
                <option value=""></option>
                {Array.isArray(resources) &&
                  resources.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
              </select>

              {formik.touched.operatorResourceId &&
                formik.errors.operatorResourceId && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      <span role="alert">
                        {formik.errors.operatorResourceId}
                      </span>
                    </div>
                  </div>
                )}
            </div>
          )}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">
              Mint Percentage
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="mintPercentage"
              {...formik.getFieldProps("mintPercentage")}
              type="number"
              name="mintPercentage"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {formik.touched.mintPercentage && formik.errors.mintPercentage && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.mintPercentage}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">
              Pool Percentage
            </label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="poolPercentage"
              {...formik.getFieldProps("poolPercentage")}
              type="number"
              name="poolPercentage"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {createError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "#f44336" }}>
                {createError}
              </span>
            </div>
          </div>
        )}

        {/* begin::Actions */}
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className="indicator-label">Submit</span>
            {formik.isSubmitting && (
              <span className="indicator-progress">
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  );
}

export {GradeEditModalForm}

import moment from "moment";
import { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { CellValue } from "react-table";

export const truncateMiddle = (word: string) => {
    const tooLongChars = 15; // arbitrary

    if (word.length < tooLongChars) {
        return word;
    }

    const ellipsis = '...';
    const charsOnEitherSide = Math.floor(tooLongChars / 2) - ellipsis.length;

    return word.slice(0, charsOnEitherSide) + ellipsis + word.slice(-charsOnEitherSide);
}

export const isUUID = ( uuid: string ) => {
    return !!("" + uuid).match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$');
}

export const useQueryParams = (): {[key: string]: string} | any => {
    const { search } = useLocation();
    const params = useMemo(() => new URLSearchParams(search), [search]);

    return new Proxy(params, {
        get(target, prop) {
            if (typeof prop === 'string') {
                return target.get(prop)
            }
        },
    });
}

export const LinkForGrades = ({param, text, type}: {param: string, text: string, type: string}) => {
    return type === 'userId' ? (
        <NavLink to={`/grades?operatorId=${param}`}>{text}</NavLink>
    ) : (
        <div>{text}</div>
    )
}
  
export const getCellContent = (cell: CellValue) => {
    if(isUUID(cell.value)) {
        return cell.column.id === 'userId' 
        ? (<LinkForGrades param={cell.value} text={truncateMiddle(cell.value)} type={cell.column.id}/>)
        : (<div>{truncateMiddle(cell.value)}</div>)
    } else if (cell.column.id === 'createdAt' || cell.column.id === 'created_at' || cell.column.id === 'timestamp') {
        return <div>{moment(cell.value).format('MM-DD-YYYY hh:mm a')}</div>
    } else if (cell.column.id === 'batch_tx_hash') {
        return <a target="_blank" href={`${process.env.REACT_APP_HASH_URL + cell.value}`} rel="noreferrer">{cell.render('Cell')}</a>
    } else {
        return cell.render('Cell')
    }
}

export const getError = (error: {response: { data: {message: string | string[]}}}) => {
    const { message } = error?.response?.data
    return typeof message === 'string' ? message : message?.join(',')
}

export const formatPriceNumberWithCurrency = ({
    number,
    cutZeros = false,
  }: {
    number: string;
    cutZeros?: boolean;
  }) => {
    const options = {
      style: "currency",
      currency: "USD",
    };
    number = number?.toString();
  
    let result = parseFloat(number).toLocaleString("en-US", options);
    const final = result.substr(result.lastIndexOf(",") + 1);
  
    if (cutZeros && final.includes("00")) {
      result =
        result?.slice(0, -5) + result?.slice(result.length - 2, result.length);
    }
    result = result.slice(1);
  
    return result || "";
  };
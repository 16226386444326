export enum BatchStatusEnum {
  CREATED = 'created',
  PROCESSING = 'processing',
  SENT = 'sent',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export type Batch = {
  batch_id: string,
  created_at: Date,
  batch_status: BatchStatusEnum,
  batch_tx_hash: string,
  status_code?: boolean,
}

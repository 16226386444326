import { useFormik } from 'formik'
import {useEffect} from 'react'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import clsx from 'clsx'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { useQueryParams } from '../../../../../../utils/utils'
import "react-datepicker/dist/react-datepicker.css";
import { useNavigateWithSearchParams } from '../../../../../../hooks/useNavigateWithSearchParams'

const BurningListFilter = () => {
  const queryParams = useQueryParams()
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const {navigateWithSearchParams} = useNavigateWithSearchParams()

  const formik = useFormik({
    initialValues: {
      operatorId: '',
    },

    onSubmit: values => {
      navigateWithSearchParams(values, '/burning-items')
    },
  });

  useEffect(() => {
    MenuComponent.reinitialization()
    const {operatorId} = queryParams;

    formik.setFieldValue('operatorId', operatorId || '')
  }, [])

  const formReset = () => {
    formik.resetForm();

    navigateWithSearchParams({}, '/burning-items')
    updateState({
      filter: {
        operatorId: '',
      },
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filter
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <form onSubmit={formik.handleSubmit}>
            <label className='form-label fs-6 fw-bolder text-dark' htmlFor="operatorId">Operator Id</label>
            <input
                className={clsx(
                  'form-control form-control-lg form-control-solid')}
                id="operatorId"
                name="operatorId"
                type="text"
                onChange={formik.handleChange}
                value={formik.values.operatorId}
            />

      
            {/* begin::Actions */}
            <div className='d-flex justify-content-end' style={{marginTop: 25}}>
              <button
                type='button'
                disabled={isLoading}
                onClick={formReset}
                className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='reset'
              >
                Reset
              </button>
              <button
                disabled={isLoading}
                type="submit"
                className='btn btn-primary fw-bold px-6'
                data-kt-menu-dismiss='true'
                data-kt-user-table-filter='filter'
              >
                Apply
              </button>
            </div>
            {/* end::Actions */}
          </form>
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {BurningListFilter}

import axios, {AxiosResponse} from 'axios'
import Cookies from 'js-cookie'
import {Response} from '../../../../../_metronic/helpers'
import {OperatorCreate} from './_models'

export const createOperator = (operator: OperatorCreate): Promise<OperatorCreate | undefined> => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/operator/register`,
    operator,
    {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
      }
    })
    .then((response: AxiosResponse<Response<OperatorCreate>>) => response.data)
    .then((response: Response<OperatorCreate>) => response.data)
}

export const updateOperator = (operator: OperatorCreate) => {
  // return authInstance
  //   .post('operator/create', operator)
  //   .then((response: AxiosResponse<Response<OperatorCreate>>) => response.data)
  //   .then((response: Response<OperatorCreate>) => response.data)
}

export const getOperatorList = (query: string): any => {
  return axios
  .get(`${process.env.REACT_APP_API_URL}/admin/get-operator-staff${query ? '?' + query : ''}`, {
    headers: {
      Authorization: 'Bearer ' + Cookies.get('token'),
    }
  })
    .then((res: any) => {
      return {...res, data: res.data.result, total: res.data.total}
    })
}

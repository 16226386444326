import axios from "axios";
import Cookies from "js-cookie";
import { useEffect, useMemo, useState } from "react";
import { Column } from "react-table";
import { TableCustomHeader } from "../../../../../components/Table/table/columns/TableCustomHeader";
import { UniversalContent } from "../../../../../components/Table/table/columns/UniversalProps";
import { BatchStatusEnum } from "../../core/_models";

const usersColumns: ReadonlyArray<Column<UniversalContent>> = [
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title="id"
        className="min-w-120px"
      />
    ),
    accessor: "batch_id",
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title="CREATED AT"
        className="min-w-250px"
      />
    ),
    accessor: "created_at",
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title="TX HASH"
        className="min-w-120px"
      />
    ),
    accessor: "batch_tx_hash",
  },
  {
    Header: (props) => (
      <TableCustomHeader
        tableProps={props}
        title="Status"
        className="text-end min-w-100px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => {
      const [loading, setLoading] = useState<boolean>(false);
      const [status, setStatus] = useState<BatchStatusEnum>();

      useEffect(() => {
        //@ts-ignore
        setStatus(props.data[props.row.index]?.batch_status);
      }, [props]);

      const setBatch = async () => {
        setLoading(true);

        await axios
          .post(
            `${process.env.REACT_APP_API_URL}/events/resend-batch`,
            {
              //@ts-ignore
              batchId: props.data[props.row.index].batch_id,
            },
            {
              headers: {
                Authorization: "Bearer " + Cookies.get("token"),
              },
            }
          )
          .then(() => {
            setStatus(BatchStatusEnum.CREATED);
          })
          .finally(() => {
            setLoading(false);
          });
      };

      const content = useMemo(
        () =>
          status === BatchStatusEnum.FAILED ? (
            <button
              onClick={setBatch}
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary w-100 mb-5"
              disabled={loading}
            >
              {!loading && <span className="indicator-label">Resend</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  Wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          ) : (
            <>{status}</>
          ),
        [status]
      );

      return content;
    },
  },
];

export { usersColumns };

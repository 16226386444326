import React from 'react';
import './style.scss';

const Toggle = ({ state = false, isLoading = false, onToggleChange = () => {} }) => {
  const onChange = () => {
    if (!isLoading) {
      onToggleChange();
    }
  };

  return (
    <div className="toggle-switch toggle-switch__rounded">
      <div className="toggle-switch__wrapper">
        <div className={`slider ${state && 'is-checked'}`} onClick={onChange}></div>
      </div>
    </div>
  );
};

export default Toggle;

import clsx from 'clsx'
import {FC, PropsWithChildren} from 'react'
import {HeaderProps} from 'react-table'
import { UniversalContent } from './UniversalProps'

export type  TableCustomHeaderProps = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<UniversalContent>>
}
export const TableCustomHeader: FC<TableCustomHeaderProps> = ({className, title, tableProps}) => {

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className,
      )}
    >
      {title}
    </th>
  )
}

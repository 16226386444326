import clsx from 'clsx'
import {FC, PropsWithChildren, useMemo} from 'react'
import {HeaderProps} from 'react-table'
import { useNavigateWithSearchParams } from '../../../../../hooks/useNavigateWithSearchParams'

import { useQueryParams } from '../../../../../utils/utils'
import { UniversalContent } from './UniversalProps'

export enum SORT_PARAMS {
  ASC = 'ASC',
  DESC = 'DESC',
}

type Props = {
  className?: string
  title?: string
  tableProps: PropsWithChildren<HeaderProps<UniversalContent>>
}
export const HeaderWithSort: FC<Props> = ({className, title, tableProps}) => {
  const {status, webhookUrl, operatorId, dateMin, dateMax, page, limit, order} = useQueryParams()

  const {navigateWithSearchParams} = useNavigateWithSearchParams()

  const orderMemo: SORT_PARAMS | undefined = useMemo(() => order || SORT_PARAMS.ASC, [order])
  const sortColumn = () => {
    let values = {status, webhookUrl, operatorId, dateMin, dateMax, page, limit, order: orderMemo === SORT_PARAMS.ASC ? SORT_PARAMS.DESC : SORT_PARAMS.ASC}

    navigateWithSearchParams(values, '/webhooks-logging')
  }

  return (
    <th
      {...tableProps.column.getHeaderProps()}
      className={clsx(
        className, order !== undefined && `table-sort-${order}`
      )}
      style={{cursor: 'pointer'}}
      onClick={sortColumn}
    >
      {title}
    </th>
  )
}

import { FC, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";

import { addTranslation, updateResource } from "../../core/_requests";
import clsx from "clsx";
import { isNotEmpty } from "../../../../../../_metronic/helpers";
import { useListView } from "../../core/ListViewProvider";
import { useQueryResponse } from "../../core/QueryResponseProvider";
import { UsersListLoading } from "../loading/UsersListLoading";
import { getError } from "../../../../../../utils/utils";
import { AddTranslationDto } from "../../core/_models";

const editUserSchema = Yup.object().shape({
  key: Yup.string()
    .min(2, "Minimum 2 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
  en: Yup.string().min(1, "Minimum 1 symbols").required("Name is required"),
});

const ResourcesEditModalForm: FC = () => {
  const { setItemIdForUpdate, itemIdForUpdate } = useListView();
  const { refetch } = useQueryResponse();
  const [createError, setCreateError] = useState("");

  const [valueForEdit] = useState<AddTranslationDto>({
    // id?: itemIdForUpdate?.id || "",
    key: itemIdForUpdate?.key || "",
    en: itemIdForUpdate?.en || "",
    et: itemIdForUpdate?.et || "",
    lv: itemIdForUpdate?.lv || "",
    es: itemIdForUpdate?.es || "",
    pt: itemIdForUpdate?.pt || "",
    fr: itemIdForUpdate?.fr || "",
    el: itemIdForUpdate?.el || "",
    ja: itemIdForUpdate?.ja || "",
    de: itemIdForUpdate?.de || "",
  });

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch();
    }
    setItemIdForUpdate(undefined);
  };

  const formik = useFormik({
    initialValues: valueForEdit,
    validationSchema: editUserSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setCreateError("");
      setSubmitting(true);
      console.log(values, "VALUES");
      try {
        if (isNotEmpty(itemIdForUpdate?.id)) {
          const filterNotChangedValues = Object.entries(values).reduce(
            (accum, [key, value]: string[]) => {
              if (
                //@ts-ignore
                itemIdForUpdate[key] !== value ||
                key === "id" ||
                key === "key"
              ) {
                //@ts-ignore
                accum[key] = value;
              }

              return accum;
            },
            {}
          );
          
          await updateResource({
            //@ts-ignore
            id: itemIdForUpdate?.id,
            ...filterNotChangedValues,
          });
        } else {
          const filterEmptyValues = Object.entries(values).reduce(
            (accum, [key, value]: string[]) => {
              if (value) {
                //@ts-ignore
                accum[key] = value;
              }

              return accum;
            },
            {}
          );

          //@ts-ignore
          await addTranslation(filterEmptyValues);
        }
        setSubmitting(true);
        cancel(true);
      } catch (ex) {
        console.error(ex);
        //@ts-ignore
        setCreateError(getError(ex));
      }
    },
  });

  return (
    <>
      <form
        id="kt_modal_add_user_form"
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className="d-flex flex-column scroll-y me-n7 pe-7"
          id="kt_modal_add_user_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_user_header"
          data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
          data-kt-scroll-offset="300px"
        >
          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">Key</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Key"
              {...formik.getFieldProps("key")}
              type="text"
              name="key"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.key && formik.errors.key },
                {
                  "is-valid": formik.touched.key && !formik.errors.key,
                }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {formik.touched.key && formik.errors.key && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.key}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="required fw-bold fs-6 mb-2">English</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="English"
              {...formik.getFieldProps("en")}
              type="text"
              name="en"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0",
                { "is-invalid": formik.touched.en && formik.errors.en },
                {
                  "is-valid": formik.touched.en && !formik.errors.en,
                }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {formik.touched.en && formik.errors.en && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.en}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Estonian</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Estonian"
              {...formik.getFieldProps("et")}
              type="text"
              name="et"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Latvian</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Latvian"
              {...formik.getFieldProps("lv")}
              type="text"
              name="lv"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Spanish</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Spanish"
              {...formik.getFieldProps("es")}
              type="text"
              name="es"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Portuguese</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Portuguese"
              {...formik.getFieldProps("pt")}
              type="text"
              name="pt"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">French</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="French"
              {...formik.getFieldProps("fr")}
              type="text"
              name="fr"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Greek</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Greek"
              {...formik.getFieldProps("gr")}
              type="text"
              name="gr"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">Japanese</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="Japanese"
              {...formik.getFieldProps("jp")}
              type="text"
              name="jp"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className="fv-row mb-7">
            {/* begin::Label */}
            <label className="fw-bold fs-6 mb-2">German</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder="German"
              {...formik.getFieldProps("de")}
              type="text"
              name="de"
              className={clsx(
                "form-control form-control-solid mb-3 mb-lg-0"
                // {'is-invalid': formik.touched.password && formik.errors.password},
                // {
                //   'is-valid': formik.touched.password && !formik.errors.password,
                // }
              )}
              autoComplete="off"
              disabled={formik.isSubmitting}
            />
            {/* {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )} */}
            {/* end::Input */}
          </div>
          {/* end::Input group */}
        </div>
        {/* end::Scroll */}

        {createError && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              <span role="alert" style={{ color: "#f44336" }}>
                {createError}
              </span>
            </div>
          </div>
        )}

        {/* begin::Actions */}
        <div className="text-center pt-15">
          <button
            type="reset"
            onClick={() => cancel()}
            className="btn btn-light me-3"
            data-kt-users-modal-action="cancel"
            disabled={formik.isSubmitting}
          >
            Discard
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            data-kt-users-modal-action="submit"
            disabled={formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className="indicator-label">Submit</span>
            {formik.isSubmitting && (
              <span className="indicator-progress">
                Please wait...{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {formik.isSubmitting && <UsersListLoading />}
    </>
  );
};

export { ResourcesEditModalForm };

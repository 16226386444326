import axios, {AxiosResponse} from 'axios'
import Cookies from 'js-cookie'
import { Response} from '../../../../../_metronic/helpers'
import {OperatorCreate, OperatorQueryResponse, OperatorRootCreate, User, UsersQueryResponse} from './_models'

const createOperator = (operator: OperatorRootCreate): Promise<OperatorCreate | undefined> => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/operator/create`, operator, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
      }
    })
    .then((response: AxiosResponse<Response<OperatorCreate>>) => response.data)
    .then((response: Response<OperatorCreate>) => response.data)
}

const updateOperator = (operator: {operatorId: string | number, address: string}) => {
  return axios
    .post(`${process.env.REACT_APP_API_URL}/admin/add-operator-address`, operator, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
      }
    })
    .then((res: any) => {
      return {...res, data: res.data.result, total: res.data.total}
    })
}

const getOperatorList = (query: string): any => {
  return axios
    .get(`${process.env.REACT_APP_API_URL}/admin/get-all-operators-ids${query ? '?' + query : ''}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
      }
    })
    .then((res: any) => {
      return {...res, data: res.data.result, total: res.data.total}
    })
}

export {createOperator, updateOperator, getOperatorList}

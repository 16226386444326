/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { BatchesChart } from './BatchesChart';
import { MainStatsChart } from './MainStatsChart';
import { EventsStatsChart } from './EventsStatsChart';
import axios from 'axios';
import { API_URL } from '../../modules/auth/core/_requests';
import Cookies from 'js-cookie';
import { TotalStatsWidget } from './TotalStatsWidget';
import { ANAUTHORIZED_CODE } from '../../../hooks/useErrorsHandler';
import { useAuth } from '../../modules/auth';
import { useNavigate } from 'react-router-dom';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

enum DataSetEnum {
  SEVEN_DAYS = 'seven_days',
  TWENTY_FOUR_HOURS = 'twenty_four_hours'
}

export enum DashboardDataStatsNames {
  totalEvents = 'totalEvents',
  totalAmount = 'totalAmount',
  sentToPool = 'sentToPool',
  sentToUser = 'sentToUser',
}

export type DashboardDataStats = {
  totalEvents: number | null,
  totalAmount: number | null,
  sentToPool: number | null,
  sentToUser: number | null,
}

export type DashboardData = {
  totalEvents: number | null,
  totalAmount: number | null,
  sentToPool: number | null,
  sentToUser: number | null,
  history: {
      date: string,
      value: DashboardDataStats,
  }[]
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  const initDashboardData: DashboardData = {
    history: [],
    sentToPool: null,
    sentToUser: null,
    totalAmount: null,
    totalEvents: null,
  }
  const [dashboardData, setDashboardData] = useState<DashboardData>(initDashboardData);

  const navigate = useNavigate()
  const {logout} = useAuth()

  useEffect(() => {
    const getStats = async () => {
      try {
          const response: any = await axios.get(`${API_URL}/dashboard/get`, {headers: {
            Authorization: 'Bearer ' + Cookies.get('token'),
          }})
          setDashboardData(response.data);
      } catch (error) {
        setDashboardData(initDashboardData);
        //@ts-ignore
        const { status } = error?.response?.data

        if (status === ANAUTHORIZED_CODE) {
          logout()
          navigate({pathname: '/auth'})
        }
      }
    };

    getStats()
  }, [])


  return (
    <>
        <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
        <div className='col-xl-8'>
            <EventsStatsChart dashboardData={dashboardData}/>
            <MainStatsChart dashboardData={dashboardData}/>
            <TotalStatsWidget dashboardData={dashboardData}/>
            <BatchesChart />
        </div>
    </>
  )
}

export {DashboardWrapper}

import {Column, HeaderProps} from 'react-table'
import { useListView } from '../../core/ListViewProvider'
import {UserCustomHeader} from './UserCustomHeader'
import pencil from '../../../../../../icons/pencil.svg'
import { UniversalContent } from '../../../../../components/Table/table/columns/UniversalProps'
import { TableCustomHeader } from '../../../../../components/Table/table/columns/TableCustomHeader'

export type Grade = {
  id: string,
  name: string,
  operatorId: string
  foreignResourceId: string,
}

const usersColumns: ReadonlyArray<Column<Grade>> = [
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='id' className='min-w-120px' />,
    accessor: 'id',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Name' className='min-w-120px' />,
    accessor: 'name',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Operator id' className='min-w-120px' />,
    accessor: 'operatorId',
  },
  {
    Header: (props) => <UserCustomHeader tableProps={props} title='Foreign Resource Id' className='min-w-120px' />,
    accessor: 'foreignResourceId',
  },
  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader tableProps={props} title='UPDATE' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => {
      const {setItemIdForUpdate} = useListView()

      const openWebhooksInfoModal = ()=> {
        setItemIdForUpdate(props.data[props.row.id])
      }

        return (
          <div onClick={openWebhooksInfoModal}>
            <img alt='' src={pencil} style={{marginRight: 20, cursor: 'pointer'}}/>
          </div>
        )
      },
  },
]

export {usersColumns}

import {FC} from 'react'
import { UniversalColumnProps } from './UniversalProps'

const CustomHeaderColumn: FC<UniversalColumnProps> = ({column}) => (
  <>
    {column.Header && typeof column.Header === 'string' ? (
      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
    ) : (
      column.render('Header')
    )}
  </>
)

export {CustomHeaderColumn}

/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useQueryParams } from "../../../../../utils/utils";

export const defaultLimit = "30";

const UsersListPagination = ({
  pathname,
  total,
  isLoading,
}: {
  pathname: string;
  total: number | undefined;
  isLoading: boolean;
}) => {
  const { page, limit } = useQueryParams();

  const initPagination = {
    page: 1,
    links: ["1", "2", "3"],
  };

  const initItemsPerPage = {
    limit: "30",
    options: ["30", "100"],
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const params: any = {};

  for (let entry of searchParams.entries()) {
    params[entry[0]] = entry[1];
  }

  const [pagination, setPagination] = useState(initPagination);
  const [itemsPerPage, setItemsPerPage] = useState(initItemsPerPage);

  const navigate = useNavigate();

  const updatePage = (page: number) => {
    navigate({
      pathname,
      search: `?${createSearchParams({
        ...params,
        page: page ? `${page}` : "1",
        limit: itemsPerPage.limit || defaultLimit,
      })}`,
    });
  };

  const updateItemsPerPage = (limit: string) => {
    navigate({
      pathname,
      search: `?${createSearchParams({
        page: page ? `${page}` : "1",
        limit: limit || defaultLimit,
      })}`,
    });
  };

  useEffect(() => {
    setItemsPerPage({ ...itemsPerPage, limit: limit || defaultLimit });
    setPagination({
      ...pagination,
      page: page || 1,
      links: total
        ? Array(Math.ceil(total / +(limit || defaultLimit)))
            .fill(null)
            .map((_, i) => `${i + 1}`)
        : ["1"],
    });
  }, [page, limit, total]);

  return (
    <div className="row">
      {/* <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div> */}
      <div className="col-sm-12 col-md-12 d-flex align-items-center justify-content-center justify-content-md-end">
        <div id="kt_table_users_paginate">
          <ul className="pagination">
            <section
              style={{ display: "flex", alignItems: "center", marginRight: 50 }}
            >
              Items per page:&nbsp;&nbsp;&nbsp;
              {itemsPerPage.options?.map((link) => (
                <li
                  key={link}
                  className={clsx("page-item", {
                    active: itemsPerPage.limit === link,
                    disabled: isLoading,
                    previous: link === "&laquo; Previous",
                    next: link === "Next &raquo;",
                  })}
                >
                  <a
                    className="page-link"
                    onClick={() => updateItemsPerPage(link)}
                    dangerouslySetInnerHTML={{ __html: link }}
                    style={{ cursor: "pointer" }}
                  />
                </li>
              ))}
            </section>
            <ReactPaginate
              breakLabel={<div style={{ marginTop: 10 }}>...</div>}
              nextLabel={null}
              onPageChange={({ selected }) => updatePage(selected + 1)}
              pageRangeDisplayed={2}
              pageCount={pagination.links?.length}
              previousLabel={null}
              className={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeLinkClassName={"page-link active"}
              activeClassName={"page-item active"}
            />
          </ul>
        </div>
      </div>
    </div>
  );
};

export { UsersListPagination };

import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {UsersTable} from './table/ResourcesTable'
import {KTCard} from '../../../../_metronic/helpers'
import { Column } from 'react-table'
import { Grade } from './table/columns/_columns_3'
import { ResourcesEditModal } from './components/resource-edit-modal/ResourcesEditModal'

const EventsList = ({columns}: {columns: ReadonlyArray<Column<Grade>>}) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <UsersTable columns={columns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <ResourcesEditModal/>}
    </>
  )
}

const EventsListWrapper = ({columns}: {columns: ReadonlyArray<Column<Grade>>}) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <EventsList columns={columns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EventsListWrapper}

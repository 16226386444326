export type WebhookLogginItem = {
  id: string,
  timestamp: Date,
  operator_id: string,
  notification_url: string,
  status_code: number | null,
  request_body: string,
  request_header: string,
  response_body: string,
  response_header: string,
  created_at: Date
}

export enum WebhooksStatusEnum {
  ERR_FR_TOO_MANY_REDIRECTS = "ERR_FR_TOO_MANY_REDIRECTS",
  ERR_BAD_OPTION_VALUE = "ERR_BAD_OPTION_VALUE",
  ERR_BAD_OPTION = "ERR_BAD_OPTION",
  ERR_NETWORK = "ERR_NETWORK",
  ERR_DEPRECATED = "ERR_DEPRECATED",
  ERR_BAD_RESPONSE = "ERR_BAD_RESPONSE",
  ERR_BAD_REQUEST = "ERR_BAD_REQUEST",
  ERR_CANCELED = "ERR_CANCELED",
  ECONNABORTED = "ECONNABORTED",
  ETIMEDOUT = "ETIMEDOUT",
  SUCCESS = 'SUCCESS'
}

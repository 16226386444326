import { useEffect } from 'react'
import {useListView} from '../core/ListViewProvider'
import { WebhooksModalForm } from './WebhooksModalForm'

const WebhookInfoModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()

  if (itemIdForUpdate) {
    return <WebhooksModalForm webhookInfo={itemIdForUpdate} />
  }

  return null
}

export {WebhookInfoModalFormWrapper}

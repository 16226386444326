/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useMemo, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import { createSearchParams, useNavigate } from "react-router-dom";
import { BatchStatusEnum } from "../../modules/batches/batches-list/core/_models";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

enum DataSetEnum {
    SEVEN_DAYS = "seven_days",
    TWENTY_FOUR_HOURS = "twenty_four_hours",
}

const BatchesChart: FC = () => {
    const navigate = useNavigate();
    const [dataSet, setDataSet] = useState<DataSetEnum | string>(DataSetEnum.SEVEN_DAYS);

    const dates = useMemo(
        () =>
        dataSet === DataSetEnum.SEVEN_DAYS
            ? [...Array(7)]
                .map((_, i) => {
                const d = new Date();
                d.setDate(d.getDate() - i);
                return moment.utc(d).format();
                })
                .reverse()
            : [...Array(24)]
                .map((_, i) => {
                const d = new Date();
                d.setHours(d.getHours() - i);
                return moment.utc(d).format();
                })
                .reverse(),
        [dataSet]
    );

    const labels = useMemo(
        () =>
        dataSet === DataSetEnum.SEVEN_DAYS
            ? dates.map((d) => moment(d).format("MM-DD-YYYY"))
            : dates.map((d) => moment(d).format("HH:mm")),
        [dataSet]
    );

    const data = useMemo(
        () => ({
            labels,
            datasets: [
                {
                    label: BatchStatusEnum.COMPLETED,
                    data: labels.map(() => Math.ceil(Math.random() * 100)),
                    backgroundColor: "rgb(75, 192, 192)",
                },
                {
                    label: BatchStatusEnum.FAILED,
                    data: labels.map(() => Math.ceil(Math.random() * 100)),
                    backgroundColor: "rgb(255, 99, 132)",
                },
            ],
        }), [dataSet]
    );

    const options = {
            plugins: {
            title: {
                display: true,
                text: "Batches statistics",
            },
        },
        responsive: true,
            scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
        onClick: (evt: any, activeEls: any) => {
            let newQuery: {
                dateMax?: string;
                dateMin?: string;
                status?: string;
            } = {};
            const dateMax = dates[activeEls[0].index] || "";
            dateMax && (newQuery.dateMax = dateMax);
            const dateMin = dates[activeEls[0].index - 1] || "";
            dateMin && (newQuery.dateMin = dateMin);
            newQuery.status = data.datasets[activeEls[0].datasetIndex].label;

            navigate({
                pathname: "/batches",
                search: `?${createSearchParams(newQuery)}`,
            });
        },
    };

    return (
        <>
            <select
                className="form-select form-select-solid fw-bolder"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                data-kt-user-table-filter="role"
                data-hide-search="true"
                onChange={(e) => {
                    setDataSet(e.target.value)
                }}
                value={dataSet}
            >
                <option value={DataSetEnum.SEVEN_DAYS}>Last 7 days</option>
                <option value={DataSetEnum.TWENTY_FOUR_HOURS}>Last 24 hours</option>
            </select>
            <div className="col-xl-12">
                <Bar options={options} data={data} />
            </div>
        </>
    );
};

export { BatchesChart };

import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {UsersListHeader} from './components/header/UsersListHeader'
import {BatchesTable} from './table/BatchesTable'
import {KTCard} from '../../../../_metronic/helpers'
import { Column } from 'react-table'
import { Batch } from './core/_models'

export const BatchesListWrapper = ({columns}: {columns: ReadonlyArray<Column<Batch>>}) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <KTCard>
          <UsersListHeader />
          <BatchesTable columns={columns} />
        </KTCard>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

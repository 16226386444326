import Cookies from 'js-cookie'
import { authInstance } from '../../../auth/core/_requests'

export const getFailedEvents = (query: any): any => {
  return authInstance
    .get(`${process.env.REACT_APP_API_URL}/admin/get-failed-events?${query}`, {
      headers: {
        Authorization: 'Bearer ' + Cookies.get('token'),
      }
    })
    .then((res: any) => {
      return {...res, data: res.data.result, total: res.data.total}
    })
}



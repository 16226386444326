import { useMemo } from "react"
import {QueryRequestProvider} from './burning-items-list/core/QueryRequestProvider'
import {QueryResponseProvider} from './burning-items-list/core/QueryResponseProvider'
import {UsersListHeader} from './burning-items-list/components/header/BurningItemsHeader'
import {BurningItemesTable} from './burning-items-list/table/BurningItemesTable'
import { BurningItemsColumns } from "./burning-items-list/table/columns/_columns_3";
import { KTCard } from "../../../_metronic/helpers"
import { ListViewProvider } from "./burning-items-list/core/ListViewProvider"


const BurningItemsPage = () => {
  const columns = useMemo(() => BurningItemsColumns, []);

  return (
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <KTCard>
            <UsersListHeader />
            <BurningItemesTable columns={columns} />
          </KTCard>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
)}

export default BurningItemsPage
import { ListViewProvider, useListView } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { GradesTable } from "./table/GradesTable";
import { KTCard } from "../../../../_metronic/helpers";
import { Column } from "react-table";
import { Grade } from "./table/columns/_columns_3";
import { GradeEditModal } from "./components/grade-edit-modal/GradeEditModal";
import { UsersListHeader } from "./components/header/UsersListHeader";

const UsersList = ({columns}: {columns: ReadonlyArray<Column<Grade>>}) => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <UsersListHeader />
        <GradesTable columns={columns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <GradeEditModal/>}
    </>
  )
}

const EventsListWrapper = ({columns}: {columns: ReadonlyArray<Column<Grade>>}) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <UsersList columns={columns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {EventsListWrapper}

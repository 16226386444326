import Cookies from "js-cookie";
import { authInstance } from "../../../auth/core/_requests";

export const getBurningItemsList = (query: any): any => {
  return authInstance
    .get(`${process.env.REACT_APP_API_URL}/admin/get-burning-items?${query}`, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((res: any) => {
      return { ...res, data: res.data.result, total: res.data.count };
    });
};

export const createBurningItem = (data: any) => {
  return authInstance
    .post("admin/add-burning-item", data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((response) => response.data);
};


export const updateBurningItem = (data: any) => {
  return authInstance
    .post("admin/edit-burning-item", data, {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
    .then((response) => response.data);
};

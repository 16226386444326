import { useMemo } from 'react';
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { usersColumns } from './users-list/table/columns/_columns_1';
import { UsersListWrapper } from './users-list/UsersList'
// import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
// import {UsersListWrapper} from './users-list/UsersList'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'User Management',
    path: '/apps/user-management/users',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const OperatorsPage = () => {

  const columns = useMemo(() => usersColumns, []);

  return (
    <>
        <PageTitle breadcrumbs={usersBreadcrumbs}>Users list</PageTitle>
        {/* @ts-ignore */}
        <UsersListWrapper columns={columns} />
    </>
  )
}

export default OperatorsPage
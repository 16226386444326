import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import { getCellContent } from '../../../../../../utils/utils'
import {User} from '../../core/_models'
import { Grade } from './_columns_3'

type Props = {
  row: Row<Grade>
}

const CustomRow: FC<Props> = ({row}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      const cellContent = getCellContent(cell)
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cellContent}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}

import React from 'react'
import { DashboardData } from './DashboardWrapper'

export const TotalStatsWidget = ({dashboardData}: { dashboardData:  DashboardData }) => {
  const round = (value: number | null) => {
      return value && Math.round(value * 100) / 100
  }

  return (
      <div className='card-rounded bg-white position-relative card-px py-15 mt-50px mb-30px'>
      {/* begin::Row  */}
      <div className='row g-0 mb-7'>
        {/* begin::Col  */}
        <div className='col mx-5'>
          <div className='fs-6 text-gray-400'>Total Events:</div>
          <div className='fs-2 fw-bolder text-gray-800'>{dashboardData?.totalEvents}</div>
        </div>
        {/* end::Col  */}

        {/* begin::Col  */}
        <div className='col mx-5'>
          <div className='fs-6 text-gray-400'>Total amount:</div>
          <div className='fs-2 fw-bolder text-gray-800'>{round(dashboardData?.totalAmount)} FWD</div>
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}

      {/* begin::Row  */}
      <div className='row g-0'>
        {/* begin::Col  */}
        <div className='col mx-5'>
          <div className='fs-6 text-gray-400'>Sent to users:</div>
          <div className='fs-2 fw-bolder text-gray-800'>{round(dashboardData?.sentToUser)} FWD</div>
        </div>
        {/* end::Col  */}

        {/* begin::Col  */}
        <div className='col mx-5'>
          <div className='fs-6 text-gray-400'>Sent to pool:</div>
          <div className='fs-2 fw-bolder text-gray-800'>{round(dashboardData.sentToPool)} $</div>
        </div>
        {/* end::Col  */}
      </div>
      {/* end::Row  */}
    </div>
  )
}
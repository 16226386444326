import { Column, HeaderProps } from "react-table";
import { TableCustomHeader } from "../../../../../components/Table/table/columns/TableCustomHeader";
import { UniversalContent } from "../../../../../components/Table/table/columns/UniversalProps";
import { useListView } from "../../core/ListViewProvider";
import { UserCustomHeader } from "./UserCustomHeader";
import eye from '../../../../../../icons/eye.svg'

export type EventFailedBodyType = {
  amount: number;
  eventId: string;
  resourceId: string;
  userId: string;
};

export type EventFailedType = {
  id: string;
  operator_id: string;
  operatorGradeId: string;
  createdAt: Date;
  event_body: EventFailedBodyType;
};

const usersColumns: ReadonlyArray<Column<EventFailedType>> = [
  {
    Header: (props) => (
      <UserCustomHeader tableProps={props} title="id" className="min-w-50px" />
    ),
    accessor: "id",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Operator Id"
        className="min-w-120px"
      />
    ),
    accessor: "operator_id",
  },
  {
    Header: (props) => (
      <UserCustomHeader
        tableProps={props}
        title="Created At"
        className="min-w-120px"
      />
    ),
    accessor: "createdAt",
  },

  {
    Header: (props: HeaderProps<UniversalContent>) => (
      <TableCustomHeader
        tableProps={props}
        title="MORE INFO"
        className="text-end min-w-60px"
      />
    ),
    id: "actions",
    Cell: ({ ...props }) => {
      const { setItemIdForUpdate } = useListView();

      const openWebhooksInfoModal = () => {
        setItemIdForUpdate(props.data[props.row.id].event_body);
      };

      const content =
        props.data[props.row.id].status_code === null ? (
          <div onClick={openWebhooksInfoModal}>
            <img
              alt=""
              src={eye}
              style={{ marginRight: 20, cursor: "pointer" }}
            />
          </div>
        ) : (
          <div onClick={openWebhooksInfoModal}>
            <img
              alt=""
              src={eye}
              style={{ marginRight: 20, cursor: "pointer" }}
            />
          </div>
        );
      return content;
    },
  },
];

export { usersColumns };

import { UsersListFilter } from './UsersListFilter'

const UsersListHeader = () => {
  return (
    <div className='card-header border-0 pt-6'>
      <div className='card-toolbar'>
        <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
          <UsersListFilter />
        </div>
      </div>
    </div>
  )
}

export {UsersListHeader}



import { FC, useState, createContext, useContext } from 'react'
import { UpdateOperatorResourceDto } from '../../../resources/resouces/core/_models'

const ListViewContext = createContext<{itemIdForUpdate?:UpdateOperatorResourceDto, setItemIdForUpdate?: any}>({})

const ListViewProvider: FC = ({children}) => {
  const [itemIdForUpdate, setItemIdForUpdate] = useState<UpdateOperatorResourceDto>()

  return (
    <ListViewContext.Provider
      value={{
        itemIdForUpdate,
        setItemIdForUpdate,
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}

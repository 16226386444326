import {UserEditModalForm} from './UserEditModalForm'
import {useListView} from '../core/ListViewProvider'

const UserEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()

  return  <UserEditModalForm user={itemIdForUpdate} />
}

export {UserEditModalFormWrapper}

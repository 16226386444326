import React from "react";
import { Line } from "react-chartjs-2";
import { DashboardData, DashboardDataStatsNames } from "./DashboardWrapper";
import { getDataset, getLabels } from "./utils";

export const options = {
    responsive: true,
    interaction: {
        mode: 'index' as const,
        intersect: false,
    },
    stacked: false,
    plugins: {
        title: {
            display: true,
            text: 'Metrics',
        },
    },
    scales: {
        y: {
            title: {
                text: 'USDT',
                display: true,
            },
            type: 'linear' as const,
            display: true,
            position: 'left' as const,
        },
        y1: {
            title: {
                text: 'FWD',
                display: true,
            },
            type: 'linear' as const,
            display: true,
            position: 'right' as const,
            grid: {
            drawOnChartArea: false,
        },
      },
    },
};

const MainStatsChart = ({dashboardData}: { dashboardData:  DashboardData }) => {
    const data = {
        labels: getLabels(dashboardData),
        datasets: [
            {
                label: "Sent to Pool",
                data: getDataset(dashboardData, DashboardDataStatsNames.sentToPool),
                fill: false,
                borderColor: "rgba(75,192,192,1)",
                yAxisID: 'y',
            },
            {
                label: "Sent to User",
                data: getDataset(dashboardData, DashboardDataStatsNames.sentToUser),
                fill: false,
                borderColor: "rgba(75,82,92,1)",
                yAxisID: 'y1',
            },
        ]
    };

    return (
        <>
            <Line data={data} options={options}/>
        </>
    );
};

export { MainStatsChart };

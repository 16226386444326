import {useListView} from '../core/ListViewProvider'
import { BurninngItemEditModalForm } from './BurninngItemEditModalForm'

const BurninngItemEditModalFormWrapper = () => {
  const {itemIdForUpdate} = useListView()

  return <BurninngItemEditModalForm burningItem={itemIdForUpdate} />
}

export {BurninngItemEditModalFormWrapper}
